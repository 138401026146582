import { DatesUtil } from 'helper/DatesUtil';
import dayjs from 'dayjs';
import { NumberLimitsUtil } from 'helper/NumberLimitsUtil';
import { PhonesUtil } from 'helper/PhonesUtil';
import { BluetoothUserStatuses, InputFieldValidator, MobileUserType, UserDialogInformationPageFormPlaceholderText } from "model/enums";
import InputFieldValidatorModel from "model/InputFieldValidatorModel";
import { UserAccessDateData } from 'model/NewUserModel';
import UserDialogForm, { AccessForm, AdditionalInformationForm, BluetoothForm, InformationForm } from "model/UserDialogForm";
import UserDialogFormError, { AccessError, AdditionalInformationError, BluetoothError, InformationError } from "model/UserDialogFormError";
import UserModel from "model/UserModel";
import UserTableRecord from "model/UserTableRecord";
import { PHONE_COUNTRY_CODE_LIST } from 'constants/arrays';
import { ConversionsUtil } from 'helper/ConversionsUtils';

export class UserFormError {
    public static readonly INIT_USER_DIALOG_BLUETOOTH_FORM_ERROR: BluetoothError = {}

    public static readonly INIT_USER_DIALOG_ACCESS_FORM_ERROR: AccessError = {}
    
    public static readonly INIT_USER_DIALOG_ADDITIONAL_INFORMATION_FORM_ERROR: AdditionalInformationError = {
        birthDateError: undefined,
        addressError: undefined,
        nationalityError: undefined,
        telephoneError: undefined,
        positionError: undefined,
        typeError: undefined,
        commentError: undefined,
    };
    
    public static readonly INIT_USER_DIALOG_INFORMATION_FORM_ERROR: InformationError = {
        userIdError: undefined,
        userNameError: undefined,
        departmentNameError: undefined,
        groupNameError: undefined
    };
    
    public static readonly INIT_USER_DIALOG_FORM_ERROR: UserDialogFormError = {
        informationError: this.INIT_USER_DIALOG_INFORMATION_FORM_ERROR,
        additionalInformationError: this.INIT_USER_DIALOG_ADDITIONAL_INFORMATION_FORM_ERROR,
        accessError: this.INIT_USER_DIALOG_ACCESS_FORM_ERROR,
        bluetoothError: this.INIT_USER_DIALOG_BLUETOOTH_FORM_ERROR
    };
}

export class UserForm {
    public static readonly INIT_COUNTRY_CODE = PHONE_COUNTRY_CODE_LIST[0];

    public static readonly INIT_LOCK_RELEASE_TIME = "5";

    public static readonly INIT_BLE_USER_TYPE = "0";

    public static readonly INIT_USER_DIALOG_BLUETOOTH_FORM: BluetoothForm = {
        username: undefined,
        email: undefined,
        mobileCountryCode: this.INIT_COUNTRY_CODE,
        mobileNumber: undefined,
        mobileUser: this.INIT_BLE_USER_TYPE,
        remoteOpenDoors: false,
        temporaryAccess: false,
        meetingRoom: false,
        lockReleaseTime: this.INIT_LOCK_RELEASE_TIME,
    }

    public static initUserDialogAccessForm = (endDateTime: dayjs.Dayjs | undefined): AccessForm => {
        return {
            startDate: DatesUtil.getCurrentDateAndTimeInDayJs(),
            endDate: endDateTime ?? null,
            startTime: DatesUtil.getCurrentDateAndTimeInDayJs(),
            endTime: endDateTime ?? null,
            isUnlimitedDateChecked: false,
            userRooms: [],
            doorGroups: [],
        }
    }
    
    public static readonly INIT_USER_DIALOG_ADDITIONAL_INFORMATION_FORM: AdditionalInformationForm = {
        birthDate: null,
        address: undefined,
        nationality: undefined,
        telephone: undefined,
        position: undefined,
        type: undefined,
        comment: undefined,
    }
    
    public static readonly INIT_USER_DIALOG_INFORMATION_FORM: InformationForm = {
        name: undefined,
        userId: undefined,
        departmentName: undefined,
        groupName: undefined,
    }

    public static getUserDialogBluetoothForm = (user: UserModel | undefined): BluetoothForm => {
        let form = this.INIT_USER_DIALOG_BLUETOOTH_FORM;
        if (user) {
            form = {
                username: user.Name && Boolean(user.Name) ? user.Name : undefined,
                email: user.Email && Boolean(user.Email) ? user.Email : undefined,
                mobileCountryCode: user.Telphone && Boolean(user.Telphone) ? PhonesUtil.extractCountryCodeFromPhoneNumber(user.Telphone) : this.INIT_COUNTRY_CODE,
                mobileNumber: user.Telphone && Boolean(user.Telphone) ? PhonesUtil.extractMobileNumberFromPhoneNumber(user.Telphone) : undefined,
                mobileUser: user.BleUserType !== undefined ? ConversionsUtil.getMobileUserTypeNameFromNumber(user.BleUserType) : MobileUserType.User,
                remoteOpenDoors: user.UserAccess?.MobileRemoteOpen && user.UserAccess.MobileRemoteOpen ? user.UserAccess.MobileRemoteOpen : false,
                temporaryAccess: user.UserAccess?.CanAuthorise ? user.UserAccess.CanAuthorise : false,
                meetingRoom: user.UserAccess?.CanBookRoom ? user.UserAccess.CanBookRoom : false,
                lockReleaseTime: user.UserAccess?.ReleaseTime !== undefined ? user.UserAccess.ReleaseTime.toString() : this.INIT_LOCK_RELEASE_TIME,
            };
        }
        return form;
    };

    public static getUserDialogAdditionalInformationForm = (user: UserModel | undefined): AdditionalInformationForm => {
        return (user ? {
                birthDate: DatesUtil.convertFormatToDayjs(user?.DateOfBirth, DatesUtil.CUSTOM_DATE_FORMAT ) ?? null,
                address: Boolean(user.Address) ? user.Address : undefined,
                nationality: Boolean(user.Nationality) ? user.Nationality : undefined,
                telephone: user.Telphone ? PhonesUtil.convertingBlePhoneFormatToReadablePhoneFormat(user.Telphone) : undefined,
                position: Boolean(user.Postion) ? user.Postion : undefined,
                type: Boolean(user.WorkType) && user.WorkType ? user.WorkType : undefined,
                comment: Boolean(user.Comment) ? user.Comment : undefined,
            }
            : this.INIT_USER_DIALOG_ADDITIONAL_INFORMATION_FORM
        );
    };
    
    public static getUserDialogInformationForm = (user: UserModel | undefined): InformationForm => {
        return (user ? {
            name: Boolean(user.Name) ? user.Name : undefined,
            userId: Boolean(user.UserNumber) ? user.UserNumber : undefined,
            departmentName: Boolean(user.Department.DepartmentName) ? user.Department.DepartmentName : undefined,
            groupName: Boolean(user.Group.GroupName) ? user.Group.GroupName : undefined,
        }
        : this.INIT_USER_DIALOG_INFORMATION_FORM
    );
    };

    public static getUserDialogAccessForm = (
        user: UserModel | undefined,
        accessEndDate: dayjs.Dayjs | undefined,
    ): AccessForm => {
        let result = this.initUserDialogAccessForm(accessEndDate);
        if (user) {
            const userAccessDateData: UserAccessDateData = DatesUtil.getUserAccessDateDataByUserAccess(user.UserAccess);
            result = {
                startDate: userAccessDateData.StartDate ?? DatesUtil.getCurrentDateAndTimeInDayJs(),
                endDate: userAccessDateData.EndDate ?? accessEndDate ?? null,
                startTime: userAccessDateData.StartTime ?? DatesUtil.getCurrentDateAndTimeInDayJs(),
                endTime: userAccessDateData.EndTime ?? accessEndDate ?? null,
                isUnlimitedDateChecked: (user.UserAccess?.BeginDate === null && user.UserAccess?.EndDate === null),
                userRooms: user.UserRooms ?? [],
                doorGroups: user.DoorGroups,
            };
        }

        return result;
    }
    
    public static getUserDialogForm = (
        selectedUserTableRecordRow: UserTableRecord | undefined,
        userList: UserModel[],
        accessEndDate: dayjs.Dayjs | undefined,
    ): UserDialogForm => {
        const user = userList.find((user) => selectedUserTableRecordRow?.globalId === user.Id);
        return {
            information: this.getUserDialogInformationForm(user),
            additionalInformation: this.getUserDialogAdditionalInformationForm(user),
            accessForm: this.getUserDialogAccessForm(user, accessEndDate),
            bluetoothForm: this.getUserDialogBluetoothForm(user),
        }
    }
}

class CommonFormFieldValidators {
    public static readonly TEXT_INPUT_FIELD_VALIDATORS: InputFieldValidatorModel[] = [
            new InputFieldValidatorModel(InputFieldValidator.SKIP_VALIDATION_IF_EMPTY),
            new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_TEXT_LENGTH_IS_TOO_LONG_THEN_X, 50),
    ];

    public static readonly DATE_INPUT_FIELD_VALIDATORS: InputFieldValidatorModel[] = [
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_NULL),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_DATE_NOT_VALID_DATE),
    ];

    public static readonly PHONE_INPUT_FIELD_VALIDATORS: InputFieldValidatorModel[] = [
        new InputFieldValidatorModel(InputFieldValidator.SKIP_VALIDATION_IF_EMPTY),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_PHONE_NUMBER_NOT_VALID),
    ];
}

export class InformationValidators {
    public static readonly USER_NAME_VALIDATORS: InputFieldValidatorModel[] = [
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_NULL),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_TEXT_LENGTH_IS_TOO_LONG_THEN_X, 50),
    ];
    
    public static readonly DEPARTMENT_NAME_VALIDATORS: InputFieldValidatorModel[] = [
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_NULL),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_TEXT_EQUAL_TO_X, UserDialogInformationPageFormPlaceholderText.DepartmentName),
    ];
    
    public static readonly GROUP_NAME_VALIDATORS: InputFieldValidatorModel[] = [
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_NULL),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_TEXT_EQUAL_TO_X, UserDialogInformationPageFormPlaceholderText.GroupName),
    ];
};

export class AdditionalInformationValidators {
    private static readonly TEXT_INPUT_FIELD_VALIDATORS: InputFieldValidatorModel[] = [...CommonFormFieldValidators.TEXT_INPUT_FIELD_VALIDATORS];

    public static readonly BIRTH_DATE_VALIDATORS: InputFieldValidatorModel[] = [
        new InputFieldValidatorModel(InputFieldValidator.SKIP_VALIDATION_IF_EMPTY),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_DATE_NOT_VALID_DATE),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_DATE_NOT_X_YEARS_OLDER_THEN_CURRENT_DATE, 12),
    ];

    public static readonly PHONE_VALIDATORS: InputFieldValidatorModel[] = [...CommonFormFieldValidators.PHONE_INPUT_FIELD_VALIDATORS];
    
    public static readonly ADDRESS_VALIDATORS: InputFieldValidatorModel[] = [...this.TEXT_INPUT_FIELD_VALIDATORS];
    
    public static readonly COMMENT_VALIDATORS: InputFieldValidatorModel[] = [...this.TEXT_INPUT_FIELD_VALIDATORS];

    public static readonly TYPE_VALIDATORS: InputFieldValidatorModel[] = [...this.TEXT_INPUT_FIELD_VALIDATORS];

    public static readonly POSITION_VALIDATORS: InputFieldValidatorModel[] = [...this.TEXT_INPUT_FIELD_VALIDATORS];
};

export class AccessValidators {
    public static readonly START_DATE_VALIDATORS: InputFieldValidatorModel[] = [...CommonFormFieldValidators.DATE_INPUT_FIELD_VALIDATORS];

    public static readonly START_TIME_VALIDATORS: InputFieldValidatorModel[] = [...CommonFormFieldValidators.DATE_INPUT_FIELD_VALIDATORS];

    public static readonly END_DATE_VALIDATORS: InputFieldValidatorModel[] = [...CommonFormFieldValidators.DATE_INPUT_FIELD_VALIDATORS];

    public static readonly END_TIME_VALIDATORS: InputFieldValidatorModel[] = [...CommonFormFieldValidators.DATE_INPUT_FIELD_VALIDATORS];

    public static readonly START_AFTER_END_VALIDATORS: InputFieldValidatorModel[] = [
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_DATE_NOT_VALID_DATE),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_FIRST_DATE_IS_BIGGER_THAN_SECOND),
    ];
    
};

export class BluetoothValidators {
    public static readonly USER_NAME_VALIDATORS: InputFieldValidatorModel[] = [...CommonFormFieldValidators.TEXT_INPUT_FIELD_VALIDATORS];

    public static readonly EMAIL_VALIDATORS: InputFieldValidatorModel[] = [
        ...CommonFormFieldValidators.TEXT_INPUT_FIELD_VALIDATORS,
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_EMAIL_IS_INVALID_FORMAT),
    ];

    public static readonly MOBILE_NUMBER_VALIDATORS: InputFieldValidatorModel[] = [
        new InputFieldValidatorModel(InputFieldValidator.SKIP_VALIDATION_IF_EMPTY),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_PHONE_NUMBER_NOT_VALID),
    ];

    public static readonly LOCK_RELEASE_VALIDATORS: InputFieldValidatorModel[] = [
        new InputFieldValidatorModel(InputFieldValidator.SKIP_VALIDATION_IF_EMPTY),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_VALUE_IS_LOWER_THEN_X, 0),
        new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_VALUE_IS_MORE_OR_EQUAL_THEN_X, NumberLimitsUtil.UBYTE_UPPER_LIMIT_OVERFLOW),
    ];
}

export const isInfoCircleDisplayed = (userBleStatus: BluetoothUserStatuses | undefined) => {
    return [BluetoothUserStatuses.Suspended, BluetoothUserStatuses.Deleted].some(item => item === userBleStatus);
}