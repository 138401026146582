import * as React from 'react';
import styled from 'styled-components';
import { getUserImage } from 'API/commands/UserCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { FilesUtil } from 'helper/FilesUtil';
import { useSelector } from 'react-redux';
import { selectSelectedUserRow } from 'redux/selectors/userSelector';

const ImageCard = () => {

  const selectedUserTableRecordRow = useSelector(selectSelectedUserRow);
  const [base64ProfilePicture, setBase64ProfilePicture] = React.useState<string>();
  const [profileImage, setProfileImage] = React.useState<HTMLImageElement>();
  const actualSite = ActualSiteLocator();

  React.useEffect(() => {
    if (selectedUserTableRecordRow?.globalId) {
      getUserImage(actualSite, Number(selectedUserTableRecordRow.globalId)).then(image => setBase64ProfilePicture(image ? image.Base64Image : undefined));
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTableRecordRow]);

  React.useEffect(() => {
    if (base64ProfilePicture) {
      FilesUtil.convertBase64ToImage(base64ProfilePicture, (img) => setProfileImage(img));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base64ProfilePicture]);

  return (
    <React.Fragment>
      {base64ProfilePicture && profileImage ? 
        <ImageCardHolder>
          <OuterImageContainer>
            <StyledBluredOuterImage src={profileImage.src} alt={"Blured Profile Picture"} />
            <InnerImageContainer>
            <StyledInnerImage 
              src={profileImage.src} alt={"Profile Picture"}
              $borderRadius={"50%"}
              $imageWidth={"138px"}
              $imageHeight={"138px"}
            />
            </InnerImageContainer>
          </OuterImageContainer>
        </ImageCardHolder>
      : null}
    </React.Fragment>
  );
};

export default ImageCard;

export const OuterImageContainer = styled.div`
  position: relative;
`;

const InnerImageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ImageCardHolder = styled.div`
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
`;

const StyledBluredOuterImage = styled.img`
  border-radius: 10px;
  width: 352px;
  height: 160px;
  max-height: 100%;
  max-width: 100%;
  object-fit: none;
  filter: blur(8px);
  opacity: 0.58;
`;

export const StyledInnerImage = styled.img<{ $imageWidth: string, $imageHeight: string, $borderRadius: string }>((props) => ({
  borderRadius: props.$borderRadius,
  width: props.$imageWidth,
  height: props.$imageHeight,
  objectFit: "cover",
}));
