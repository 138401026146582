import { BluetoothMenuDialogs, BluetoothUserStatuses, CardActions, CardToolTipViewOptions, DialogToOpen, MobileUserType, MobileUserTypeRequest } from "model/enums";
import RoomModel from "model/RoomModel";
import RoomTableRecords from "model/RoomTableRecords";
import { giveBackMasterLevelLastElement } from "./FormatHelper";
import MasterLevel from "model/MasterLevel";

export class ConversionsUtil {

    public static mapToRooms = (rooms: RoomModel[], listOfMasterLevels: MasterLevel[]): RoomTableRecords[] => 
        rooms.map((item) => ({
            doorName: item.Name,
            lockType: item.RoomType,
            buildingNo: item.BuildingNo,
            floorNo: item.FloorNo,
            LockNo: item.RoomNo,
            masterCardLevel: giveBackMasterLevelLastElement(item.MasterLevels, listOfMasterLevels),
        }));

    public static cardToolTipToDialogToOpen = (CardToolTipType: CardToolTipViewOptions | undefined): DialogToOpen | undefined => {
        switch(CardToolTipType) {
            case CardToolTipViewOptions.EditUser:
                return DialogToOpen.EditUserDialog;
            case CardToolTipViewOptions.CreateCard:
                return DialogToOpen.CreateCardDialog;
            case CardToolTipViewOptions.DeleteUser:
                return DialogToOpen.DeleteUserDialog;
            case CardToolTipViewOptions.ReissueCard:
                return DialogToOpen.ReissueCardDialog;
            case CardToolTipViewOptions.EraseCard:
                return DialogToOpen.EraseCardReadActionDialog;
            case CardToolTipViewOptions.ReportLost:
                return DialogToOpen.ReportLostDialog;
            case CardToolTipViewOptions.EditCard:
                return DialogToOpen.EditCardDialog;
            default:
                return undefined;
        }
    }

    public static cardToolTipToCardActions = (CardToolTipType: CardToolTipViewOptions | undefined): CardActions | undefined => {
        switch(CardToolTipType) {
            case CardToolTipViewOptions.EditUser:
            case CardToolTipViewOptions.EditCard:
                return CardActions.Edit;
            case CardToolTipViewOptions.CreateCard:
                return CardActions.CreateCard;
            case CardToolTipViewOptions.DeleteUser:
                return CardActions.Delete;
            case CardToolTipViewOptions.ReissueCard:
                return CardActions.ReissueCard;
            case CardToolTipViewOptions.EraseCard:
                return CardActions.EraseCard;
            case CardToolTipViewOptions.ReportLost:
                return CardActions.ReportLostCard;
            default:
                return undefined;
        }
    }

    public static getMobileUserTypeRequest = (type: string | undefined) => {
        switch (type) {
            case MobileUserType.User:
                return MobileUserTypeRequest.User;
            case MobileUserType.Administrator:
                return MobileUserTypeRequest.Administrator;
            case MobileUserType.Engineer:
                return MobileUserTypeRequest.Engineer;
            default:
                return undefined;
        }
    }

    public static getMobileUserTypeNameFromNumber = (type: number | undefined) => {
        switch (type) {
            case MobileUserTypeRequest.User:
                return MobileUserType.User;
            case MobileUserTypeRequest.Administrator:
                return MobileUserType.Administrator;
            case MobileUserTypeRequest.Engineer:
                return MobileUserType.Engineer;
            default:
                return MobileUserType.User;
        }
    }

    public static getBleUserStateFromBluetoothMenuDialog = (dialog?: BluetoothMenuDialogs): BluetoothUserStatuses | undefined => {
        switch(dialog) {
            case BluetoothMenuDialogs.ChangeMobile:
              return BluetoothUserStatuses.DeactivatedDevice;
            case BluetoothMenuDialogs.Suspend:
              return BluetoothUserStatuses.Suspended;
            case BluetoothMenuDialogs.Delete:
              return BluetoothUserStatuses.Deleted;
            case BluetoothMenuDialogs.ReSend:
            case BluetoothMenuDialogs.Suspended:
            case BluetoothMenuDialogs.Deleted:
              return BluetoothUserStatuses.Activated;
            default:
              return undefined;
          }
    }

    public static getNotificationFromBleUserState = (dialog?: BluetoothMenuDialogs): string | undefined => {
        switch(dialog) {
            case BluetoothMenuDialogs.Delete:
                return "The user is deleted on the NSP Ble Cloud";
            case BluetoothMenuDialogs.Deleted:
            case BluetoothMenuDialogs.Suspended:
                return "The user is activated on the NSP Ble Cloud";
            case BluetoothMenuDialogs.ChangeMobile:
                return "The user device is deactivated on the NSP Ble Cloud";
            case BluetoothMenuDialogs.Suspend:
                return "The user is suspended on the NSP Ble Cloud";
            default:
                return undefined;
        }
        
    }
}