import * as React from 'react';
import { DeleteButtonStyle, StyledButtonHolder, StyledDialogActions, StyledDialogActionsHolder } from '../../userCardDialog/UserCardDialog';
import StyledButton from 'components/controls/button/StyledButton';
import { blue, red, white } from 'constants/theme';

type Props = {
    displayButtonName: string,
    isDialogOpenAsEdit: boolean;
    isSaveButtonDisabled: boolean;
    handleClickOnClose: () => void;
    setSubmitClicked: () => void;
    setIsDeleteDialogOpen: () => void;
};

const DialogActionButtons = ({
    displayButtonName,
    isDialogOpenAsEdit,
    isSaveButtonDisabled,
    handleClickOnClose,
    setSubmitClicked,
    setIsDeleteDialogOpen,
}: Props) => {

    return (
        <StyledDialogActions>
            <StyledDialogActionsHolder>
                {isDialogOpenAsEdit &&
                    <StyledButton
                        buttonHeight={37}
                        buttonWidth={120}
                        displayedName={`Delete ${displayButtonName}`}
                        handleOnClick={setIsDeleteDialogOpen}
                        isFilledButton
                        backgroundColor={red}
                        style={DeleteButtonStyle}
                    />
                }
                <StyledButtonHolder>
                    <StyledButton
                        buttonHeight={37}
                        buttonWidth={95}
                        displayedName={"Cancel"}
                        handleOnClick={handleClickOnClose}
                        backgroundColor={white}
                    />
                    <StyledButton
                        handleOnClick={setSubmitClicked}
                        displayedName={isDialogOpenAsEdit ? "Save" : `Add ${displayButtonName}`}
                        buttonHeight={37}
                        buttonWidth={111}
                        isFilledButton
                        backgroundColor={blue}
                        disabled={isSaveButtonDisabled}
                    />
                </StyledButtonHolder>
            </StyledDialogActionsHolder>
        </StyledDialogActions>
    );
};

export default DialogActionButtons;
