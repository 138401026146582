import React  from 'react';
import { black, red, white } from "constants/theme";
import DialogTexts from "model/DialogTexts";
import TemplateDialog from "./TemplateDialog";
import { deleteUser } from 'API/commands/UserCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { useSelector } from 'react-redux';
import { selectIsBleUserLoading } from 'redux/selectors/userSelector';

type Props = {
    handleSubmit: () => void;
    handleClose: () => void;
    open: boolean;
    userId?: number;
  };

const DeleteDialog = ({
    handleSubmit, 
    handleClose, 
    open,
    userId,
}: Props) => {

    const actualSite = ActualSiteLocator();

    const isBleUserLoading = useSelector(selectIsBleUserLoading);

    const handleDeleteDialogSubmit = () => {
        if (userId && !isBleUserLoading) {
            deleteUser(actualSite, userId).then(() => handleSubmit());
        }
    }

    return <TemplateDialog dialogText={ConfirmationDialogText} open={open} handleSubmit={handleDeleteDialogSubmit} handleClose={handleClose} style={ConfirmationDialogStyle} />;
}

export default DeleteDialog;

const ConfirmationDialogText = new DialogTexts(
    "Delete User",
    "Are you sure you want to delete this user? They will not be able to access any locks with their card.",
    "Delete User",
    "Cancel"
);

const ConfirmationDialogStyle = {
  dialog: { color: black, width: '29vw', borderRadius: '20px', textAlign: 'center' as const, marginBottom: '38vh'},
  title: { alignSelf: 'center', fontWeight: 'bold' },
  content: { paddingBottom: '0' },
  contentText: { color: 'rgba(0, 0, 0, 1)' },
  actions: { justifyContent: 'end', padding: '24px 32px' },
  submitButton: {
      backgroundColor: red,
      color: white,
      borderRadius: '8px',
      padding: '8px 24px',
      textTransform: 'none' as const,
      marginLeft: '1vw'
  },
  cancelButton: {
      color: black,
      border: '1px solid rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      padding: '8px 24px',
      textTransform: 'none' as const,
      fontWeight: 'bold'
  },
};