enum CardDialogTypes {
  SET_CARD_DIALOG_FORM = 'SET_CARD_DIALOG_FORM',
  SET_CARD_DIALOG_FORM_ERROR = 'SET_CARD_DIALOG_FORM_ERROR',
  SET_CARD_DIALOG_IS_EDITED = 'SET_CARD_DIALOG_IS_EDITED',
  SET_CARD_DIALOG_IS_VALIDATION_OPEN = 'SET_CARD_DIALOG_IS_VALIDATION_OPEN',
  SET_CARD_DIALOG_CONFIRMATION_DIALOG_SUBMIT = 'SET_CARD_DIALOG_CONFIRMATION_DIALOG_SUBMIT',
  SET_CARD_DIALOG_IS_OPEN = 'SET_CARD_DIALOG_IS_OPEN',
}

export default CardDialogTypes;
