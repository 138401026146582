import * as React from 'react';
import { Box, List } from '@mui/material';
import { grey } from 'constants/theme';
import CardModel from 'model/CardModel';
import { setOverflowContainerWithText} from 'helper/Util';
import { CardDetailWindow, PageNavigation } from 'model/enums';
import { StyledGrid, StyledListGrid } from '../UserWindowCommonStyle';
import { useSelector } from 'react-redux';
import { selectPageNavigation } from 'redux/selectors/userSelector';

type Props = {
  cardModelToDisplay: CardModel | null;
};

const CardDetailsWindowCardStatusBody = ({cardModelToDisplay}: Props) => {

  const pageNavigation = useSelector(selectPageNavigation);
  const cardDetailWindowComponents = Object.values(CardDetailWindow).filter(detail => 
    (pageNavigation === PageNavigation.ManageUsers && detail !== CardDetailWindow.MasterNumber)
    || pageNavigation === PageNavigation.ManageCards
  );

  const windowElementHandler = Object.freeze({
    [CardDetailWindow.CardType]: () => setOverflowContainerWithText(CardDetailWindow.CardType, cardModelToDisplay?.Type),
    [CardDetailWindow.MasterNumber]: () => setOverflowContainerWithText(CardDetailWindow.MasterNumber, cardModelToDisplay?.MasterNo),
    [CardDetailWindow.WriteCardTime]: () => setOverflowContainerWithText(CardDetailWindow.WriteCardTime, cardModelToDisplay?.CreatedOn),
    [CardDetailWindow.LimitedDate]: () => setOverflowContainerWithText(CardDetailWindow.LimitedDate, cardModelToDisplay?.ExpiryDate),
    [CardDetailWindow.OperatorLogonCode]: () => setOverflowContainerWithText(CardDetailWindow.OperatorLogonCode, cardModelToDisplay?.CreatedBy),
    [CardDetailWindow.Issue]: () => setOverflowContainerWithText(CardDetailWindow.Issue, cardModelToDisplay?.IssueNo),
    [CardDetailWindow.Remark]: () => setOverflowContainerWithText(CardDetailWindow.Remark, cardModelToDisplay?.Remark),
  });
  
  const callWindowElements = (optionElement: string) => {
    return windowElementHandler[optionElement].call();
  }

  return (
    <List component="div" >
        <Box sx={{ width: '100%' }}>
            <StyledListGrid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3}}>
                { cardDetailWindowComponents.map((optionElement, index) => (
                  <React.Fragment key={index}>
                    <StyledGrid item xs={6}>
                      {optionElement}
                    </StyledGrid>
                    <StyledGrid item xs={6} color={grey}>
                      {callWindowElements(optionElement)}
                    </StyledGrid>
                  </React.Fragment>
                ))}
            </StyledListGrid>
        </Box>
    </List>
  );
};

export default CardDetailsWindowCardStatusBody;