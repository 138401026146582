import * as React from 'react';
import { Divider, ListItemIcon, MenuItem, MenuList, Select } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { black, blue, dialogMenuOptionBorderGray, lightGrayBackground, listItemLightGray, red, white } from 'constants/theme';
import styled from 'styled-components';
import { displayInputName, SelectFieldMenuProps } from 'helper/Util';
import { ReactComponent as ShevronDownWhiteIcon} from 'media/chevronDownWhite.svg';
import { ReactComponent as ShevronDownBlackIcon} from 'media/chevronDownBlack.svg';
import { StyledInputFieldComponents } from '../ControlsCommonStyle';

type Props = {
    inputFieldHeight: string;
    inputFieldWidth: string;
    displayedName?: string;
    placeholderInput?: string;
    inputValue?: string;
    isDisabled?: boolean;
    selectOptions: string[];
    isRequired?: boolean;
    invalidFormMessage?: string;
    setValue: (value: string, selectedIndex: number) => void;
};

const SelectField = ({
    inputFieldHeight,
    inputFieldWidth,
    displayedName = "",
    placeholderInput = "",
    inputValue,
    isDisabled = false,
    selectOptions,
    isRequired = false,
    invalidFormMessage,
    setValue,
}: Props) => {

    const IS_FORM_INVALID = (!!invalidFormMessage);

    const IS_EMPTY = !inputValue || inputValue === placeholderInput;

    return (
        <StyledInputFieldComponents.StyledInputOuterContentHolder
            width={inputFieldWidth}
        >
            <StyledInputFieldComponents.StyledInputInnerContentHolder
                $hasLabel
            >
                <StyledInputFieldComponents.StyledTextLabelHolder>
                    {displayInputName(displayedName, isRequired)}
                </StyledInputFieldComponents.StyledTextLabelHolder>
                <StyledInputFieldComponents.StyledInputHolder
                    height={inputFieldHeight}
                    width={inputFieldWidth}
                    $isInputSelected={true}
                >
                    <StyledSelectDiv
                        height={inputFieldHeight}
                        width={inputFieldWidth}
                        placeholder={placeholderInput}
                        disabled={isDisabled}
                        value={inputValue ? inputValue : placeholderInput}
                        renderValue={(selected) => selected}
                        IconComponent={(component: any) => StyledSvgIconDisplayer(component, true)}
                        MenuProps={SelectFieldMenuProps()}
                        required={isRequired}
                        $isError={IS_FORM_INVALID}
                        $isEmpty={IS_EMPTY}
                    >
                        <StyledMenuList disablePadding={true}>
                            {[...selectOptions, placeholderInput].map((optionElement, index) => (
                                <StyledMenuItemHolder                                         
                                    hidden={placeholderInput === optionElement}
                                    key={index}
                                >
                                    <StyledMenuItem
                                        onClick={() => setValue(optionElement, index)}
                                        value={optionElement}
                                        selected={optionElement === inputValue}
                                    > 
                                        <strong>{optionElement}</strong>{optionElement === inputValue && (
                                            <ListItemIcon>
                                                <FilterCheckIcon/>
                                            </ListItemIcon>
                                        )}
                                    </StyledMenuItem>
                                    <StyledDivider />
                                </StyledMenuItemHolder>
                            ))}
                        </StyledMenuList>
                    </StyledSelectDiv>
                </StyledInputFieldComponents.StyledInputHolder>
                { IS_FORM_INVALID ?
                    <StyledInputFieldComponents.StyledErrorTextHolder>
                        {invalidFormMessage}
                    </StyledInputFieldComponents.StyledErrorTextHolder>
                : null }
            </StyledInputFieldComponents.StyledInputInnerContentHolder>
        </StyledInputFieldComponents.StyledInputOuterContentHolder>
    );
};

export default SelectField;

const StyledSelectDiv = styled(Select) <{ height?: string, width?: string, $isEmpty?: boolean, $isError?: boolean }>((props) => ({
    height: `${props.height}`,
    width: `${props.width}`,
    borderRadius: `8px`,
    fontSize: `16px`,
    fontWeight: `400`,
    lineHeight: `24.35px`,
    color: props.$isEmpty ? `${listItemLightGray}` : `${black}`,
    '& fieldset' : { border: (props.$isError ? `1px solid ${red}` : `1px solid ${dialogMenuOptionBorderGray}`) },
    'div' : { padding: '8px'},
}));

const StyledMenuItem = styled(MenuItem)<{ hidden?: boolean }>((props) => ({
    backgroundColor: white,
    display: (props.hidden ? 'none' : 'flex'),
    gap: '8px',
})); 

const StyledMenuItemHolder = styled.div<{ }>((props) => ({ 
    padding: '0px !important',
    '& :hover' : { backgroundColor: lightGrayBackground },
}));

const StyledMenuList = styled(MenuList)<{ }>((props) => ({
    paddingTop: '0px !important', 
    paddingBottom: '0px !important',
 }));

 const StyledDivider = styled(Divider)`
    margin-top: 0px !important;
    margin-bottom: 0px !important;
`;

const FilterCheckIcon = styled(CheckIcon) ({
    color: blue, 
    fontSize: 20,
});

const StyledIconHolder = styled.span<{top?: string}>((props: any) => ({
    position: 'relative',
    top: props.top ?? '-4px',
}));

export const StyledSvgIconDisplayer = (props: any, isIconBlack?: boolean) => {
    return (
        <StyledIconHolder top={props.top}>
            { isIconBlack ? <ShevronDownBlackIcon {...props} /> : <ShevronDownWhiteIcon {...props} /> }
        </StyledIconHolder>
    );
};
