import { DatesUtil } from "helper/DatesUtil";
import CardDialogForm, { CardDetailsForm, CardLevelsForm } from "model/CardDialogForm";
import { useSelector } from "react-redux"
import { selectLastUsedDate } from "redux/selectors/lastUsedDateSelector"
import { selectMasterLevelList } from "redux/selectors/masterLevelSelector";

export const useCardDialogFormConst = () => {
    const limitedDate = useSelector(selectLastUsedDate);
    const listOfMasterLevels = useSelector(selectMasterLevelList);
    
    const cardLevelForms: CardLevelsForm[] = listOfMasterLevels.map(
        (masterLevel) => ({
          masterLevel,
          isChecked: false,
        }),
    );

    const emptyCardDialogForm: CardDetailsForm = { 
        lockReleaseTime: '5',
        startDate: DatesUtil.getCurrentDateAndTimeInDayJs(),
        startTime: DatesUtil.getCurrentDateAndTimeInDayJs(),
        lastDate: limitedDate ?? null,
        lastTime: limitedDate ?? null,
    };
    
    return ( 
        new CardDialogForm(
            emptyCardDialogForm, 
            cardLevelForms, 
            [], [])
    );
}