import * as React from 'react';
import UserTableRecord from 'model/UserTableRecord';
import { Box, List, ListItemButton } from '@mui/material';
import { grey } from 'constants/theme';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { selectUserList } from 'redux/selectors/userSelector';
import UserModel from 'model/UserModel';
import { StyledCollapse, StyledGrid, StyledListGrid, StyledListItemTextHeader } from './UserWindowCommonStyle';
import { AdditionalInfoElements } from 'model/enums';
import { setOverflowContainerWithText } from 'helper/Util';

type Props = {
  selectedUserTableRecordRow: UserTableRecord;
};

const AdditionalInfoCard = ({selectedUserTableRecordRow}: Props) => {

  const [openAdditionalInformation, setOpenAdditionalInformation] = React.useState(false);
  const userList: UserModel[] = useSelector(selectUserList);
  const selectedUser = userList.find(u => Number(u.UserNumber) === selectedUserTableRecordRow.userId);

  const windowElementHandler = Object.freeze({
    [AdditionalInfoElements.BirthDate]: () => setOverflowContainerWithText(AdditionalInfoElements.BirthDate, selectedUser?.DateOfBirth),
    [AdditionalInfoElements.Position]: () => setOverflowContainerWithText(AdditionalInfoElements.Position, selectedUser?.Postion),
    [AdditionalInfoElements.Nationality]: () => setOverflowContainerWithText(AdditionalInfoElements.Nationality, selectedUser?.Nationality),
  });

  const callWindowElements = (optionElement: string) => {
    return windowElementHandler[optionElement].call();
  }

  const handleClickAdditionalInformationMenu = () => {
    setOpenAdditionalInformation(!openAdditionalInformation);
  };

  const additionalInfoComponents = Object.values(AdditionalInfoElements);

  return (
    <List
      sx={{ width: '100%'}}
      component="nav"
    >
      <ListItemButton onClick={handleClickAdditionalInformationMenu}>
        <StyledListItemTextHeader >
          Additional Information
        </StyledListItemTextHeader>
        {openAdditionalInformation ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <StyledCollapse in={openAdditionalInformation} timeout="auto" unmountOnExit>
        <List component="div" >
          <Box sx={{ width: '100%' }}>
            <StyledListGrid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3}}>
                { additionalInfoComponents.map((optionElement, index) => (
                  <React.Fragment key={index}>
                    <StyledGrid item xs={6}>
                      {optionElement}
                    </StyledGrid>
                    <StyledGrid item xs={6} color={grey}>
                      {callWindowElements(optionElement)}
                    </StyledGrid>
                  </React.Fragment>
                ))}
            </StyledListGrid>
          </Box>
        </List>
      </StyledCollapse>
    </List>
  );
};

export default AdditionalInfoCard;
