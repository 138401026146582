import postCommand, { notifyCommandError } from './Command';
import { CommandCategory, EncoderCommand } from 'model/enums';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';
import EncoderModel from 'model/EncoderModel';

export const GetEncoders = async (siteName: string) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.EncoderService,
      EncoderCommand.GetEncoders,
      [],
    );
    if (!data || !data.data) {
      notifyCommandError('No encoder found or the server timed out.', null);
      return [];
    }
    const listOfEncoders: EncoderModel[] = data.data;
    return listOfEncoders;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing GetEncoder command', error);
    return [];
  }
};
