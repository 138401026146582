import * as React from 'react';
import { DialogToOpen, ReadCardDialogTexts, ReadCardStatus } from 'model/enums';
import { useDispatch } from 'react-redux';
import { setOpenedDialog, setUsersSelectedUserRow } from 'redux/actions/userActions';
import { useSignalrEncoderEvents } from 'hooks/useSignalrEncoder';
import { ReactComponent as ReadCardInfoFrame } from 'media/readCardInfoFrame.svg';
import { ReactComponent as ReadCardFailedFrame } from 'media/readCardFailedFrame.svg';
import DialogTexts from 'model/DialogTexts';
import { blue } from 'constants/theme';
import { useSelector } from 'react-redux';
import { selectOpenedDialog, selectUser } from 'redux/selectors/userSelector';
import DialogTemplate from '../DialogTemplate';
import { selectEncoderCardResponse, selectEncoderReadCardStatus, selectSelectedEncoder } from 'redux/selectors/encoderSelector';
import { setEncoderReadCardStatus } from 'redux/actions/encoderActions';
import { isEncoderSelectedOrError } from 'helper/EncoderUtils';
import { readCard } from 'API/commands/CardCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const ReadCardDialog = () => {
    const selectedEncoder = useSelector(selectSelectedEncoder);
    const isOpenedDialog = useSelector(selectOpenedDialog);
    const readCardStatus = useSelector(selectEncoderReadCardStatus);
    const encoderCardResponse = useSelector(selectEncoderCardResponse);
    const selectedUser = useSelector(selectUser(Number(encoderCardResponse?.UserId)));
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();
    useSignalrEncoderEvents();

    const handleDialogClose = () => {
        dispatch(setOpenedDialog(undefined));
        dispatch(setEncoderReadCardStatus(ReadCardStatus.None));
    }

    const fetchDialogTextByReadCardStatus = () => {
        switch(readCardStatus) {
            case ReadCardStatus.None:
                return (<React.Fragment>
                    <div><ReadCardInfoFrame /></div>
                    <div>Please, place your card on the encoder</div>
                    <div>to see details about the card.</div>
                </React.Fragment>);
            case ReadCardStatus.ReadFailed:
                return (<React.Fragment>
                    <div><ReadCardFailedFrame /></div>
                    <div>Something went wrong.</div>
                    <div>Please try again.</div>
                </React.Fragment>);
            case ReadCardStatus.ReadBlankCard:
                return (<React.Fragment>
                    <div><ReadCardFailedFrame /></div>
                    <div>This card is blank.</div>
                    <div>Please try again with a different card.</div>
                </React.Fragment>);
            default:
                return (<React.Fragment>
                    <CircularProgressHolder>
                        <CircularProgress size={60} />
                    </CircularProgressHolder>
                </React.Fragment>)
        }
    }

    const readCardDialogText: DialogTexts ={
        title: ReadCardDialogTexts.ReadDialogTitle,
        contentText: fetchDialogTextByReadCardStatus(),
        submitButton: 'Read Card',
        cancelButton: 'Cancel',
    };

    const handleSubmit = () => {
        if (!isEncoderSelectedOrError(selectedEncoder)) {
            return;
        }
        if (selectedEncoder) {
            readCard(actualSite, selectedEncoder.Id);
            dispatch(setEncoderReadCardStatus(ReadCardStatus.ReadPending));
        }
    }

    React.useEffect(() => {
        if (readCardStatus === ReadCardStatus.ReadSuccess) {
            if (selectedUser) {
                dispatch(setUsersSelectedUserRow({
                    name: selectedUser.Name,
                    department: selectedUser.Department.DepartmentName,
                    userId: Number(selectedUser.UserNumber),
                    globalId: selectedUser.Id,
                    cardId: selectedUser.CardID,
                    group: selectedUser.Group.GroupName,
                    cardInUse: selectedUser.Card?.CardStatus,
                    cardType: selectedUser.Card?.CardType,
                    limitedDate: selectedUser.Card?.Expiry
                }));
            }
            handleDialogClose();
        }
    }, [readCardStatus]);

    return (
        <React.Fragment>
            <DialogTemplate
                handleSubmit={handleSubmit}
                handleClose={handleDialogClose}
                open={isOpenedDialog === DialogToOpen.ReadCardDialog}
                dialogText={readCardDialogText}
                submitButtonColor={blue}
                dialogId={ReadCardDialogTexts.ReadDialogTitle}
                isSubmitButtonHidden={readCardStatus === ReadCardStatus.ReadPending}
            />
        </React.Fragment>
    );
}

export default ReadCardDialog;

const CircularProgressHolder = styled.div((props) => ({
    height: "100px", 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center"
}));