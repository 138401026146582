import { useState, useEffect } from 'react';
import CardDialogForm, {
  CardDetailsForm,
  CardLevelsForm,
} from 'model/CardDialogForm';
import { useSelector } from 'react-redux';
import { selectSelectedUser } from 'redux/selectors/userSelector';
import UserModel from 'model/UserModel';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';
import { convertSingleUserModelToOptionModel } from './useConversion';
import { DatesUtil } from 'helper/DatesUtil';
import { selectDepartmentList } from 'redux/selectors/departmentSelector';
import { getCardById } from 'API/commands/CardCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import CardModel from 'model/CardModel';
import { useRoomsInit } from './useStorageInits';
import { selectCardDialogIsEdited, selectCardDialogIsOpen } from 'redux/selectors/cardDialogSelector';
import { useCardDialogFormConst } from './useCardDialogForm';
import { selectLastUsedDate } from 'redux/selectors/lastUsedDateSelector';
import { selectDoorGroupsList } from 'redux/selectors/doorGroupSelector';

export function useCardFormBuilder() {
  const listOfMasterLevels = useSelector(selectMasterLevelList);
  const selectedUser = useSelector(selectSelectedUser);
  const listOfRooms = useRoomsInit();
  const listOfDepartments = useSelector(selectDepartmentList);
  const actualSite = ActualSiteLocator();
  const isEdited = useSelector(selectCardDialogIsEdited);
  const isOpen = useSelector(selectCardDialogIsOpen);
  const initCardDialogForm = useCardDialogFormConst();
  const [cardDialogForm, setCardDialogForm] = useState<CardDialogForm>(initCardDialogForm);
  const lastUsedDate = useSelector(selectLastUsedDate);
  const listOfDoorGroups = useSelector(selectDoorGroupsList);

  const initCardLevels = (cardModel: CardModel | null, user?: UserModel): CardLevelsForm[] => {
    return (user && user?.Card === null) || (user && isEdited)
      ? listOfMasterLevels.map((masterLevel) => ({
          masterLevel,
          isChecked: cardModel
            ? cardModel.Remark.includes(masterLevel.ID.toString())
            : false,
        }))
      : initCardDialogForm.cardLevels;
  };

  const initCardDetails = (user?: UserModel): CardDetailsForm => {
    const beginDateAndTime = DatesUtil.convertFormatToDayjs(user?.UserAccess?.BeginDate, DatesUtil.CUSTOM_ISO_FORMAT);
    const lastDateAndTime = DatesUtil.convertFormatToDayjs(user?.UserAccess?.EndDate, DatesUtil.CUSTOM_ISO_FORMAT);

    return (user && user?.Card === null) || (user && isEdited)
      ? {
          user: convertSingleUserModelToOptionModel(user),
          startDate: beginDateAndTime ?? DatesUtil.getCurrentDateAndTimeInDayJs(),
          startTime: beginDateAndTime ?? DatesUtil.getCurrentDateAndTimeInDayJs(),
          lastDate: lastDateAndTime ?? lastUsedDate,
          lastTime: lastDateAndTime ?? lastUsedDate,
          department: listOfDepartments.find(department => department.Id === user.Department.Id),
          lockReleaseTime: user.UserAccess?.ReleaseTime?.toString(),
          isUnlimitedDateChecked: !beginDateAndTime && !lastDateAndTime,
          building: undefined,
          floor: undefined,
          isLastDateChecked: !!lastDateAndTime,
          isStartDateChecked: !!beginDateAndTime,
        }
      : initCardDialogForm.cardDetails;
  };

  useEffect(() => {
    (async () => {
      if (isOpen) {
        let cardModel: CardModel | null = null;

        if (selectedUser && selectedUser.Card) {
          cardModel = await getCardById(actualSite, Number(selectedUser?.Card?.CardID));
        }

        setCardDialogForm({
          cardDetails: initCardDetails(selectedUser),
          cardLevels: initCardLevels(cardModel, selectedUser),
          cardDoors: listOfRooms.filter((room) =>
            selectedUser?.UserRooms?.some(
                (UserRoom) => UserRoom.RoomId === room.Id,
              ),
          ),
          doorGroups: listOfDoorGroups.filter((group) => 
            selectedUser?.DoorGroups?.some(
              (userGroup) => userGroup.Id === group.Id
            )
          ),
          userCard: cardModel ?? undefined
        });
      }
    })();
  }, [selectedUser, isEdited, isOpen]);

  return cardDialogForm;
}
