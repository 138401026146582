import CardModel from "model/CardModel";
import { CardModelActions } from "redux/constants/cardModelActions";
import CardModelActionTypes from "redux/constants/cardModelActionTypes";

type CardModelListType = {
    loading: boolean,
    cardModelList: CardModel[],
    error: string
}

const initialState: CardModelListType = {
    loading: true,
    cardModelList: [],
    error: ''
}

export const cardModelReducer = (
    state: CardModelListType = initialState,
    action: CardModelActions,
  ) => {
    switch (action.type) {
      case CardModelActionTypes.FETCH_USER_CARD_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CardModelActionTypes.FETCH_USER_CARD_SUCCESS:
        return {
          loading: false,
          cardModelList: (action.payload.Id ? [...state.cardModelList, action.payload] : [...state.cardModelList]),
          error: '',
        };
      case CardModelActionTypes.FETCH_USER_CARD_FAILURE:
      case CardModelActionTypes.FETCH_EMPTY_USER_CARDS_REQUEST:
        return {
          loading: false,
          cardModelList: [],
          error: action.payload,
        };
      default:
        return state;
    }
};