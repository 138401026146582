import * as React from 'react';
import { List } from '@mui/material';
import styled from 'styled-components';
import { listItemLightGray } from 'constants/theme';
import CustomAddCardSelect from 'components/cards/CustomAddCardSelect';
import { CardTypeUtils } from 'helper/CardTypeUtils';
import { useDispatch } from 'react-redux';
import { setOpenedDialog } from 'redux/actions/userActions';

const CardDetailsWindowNoCardBody = () => {

  const dispatch = useDispatch();
  return (
    <List component="div" >
        <StyledText>
            User has no card assigned.
        </StyledText>
        <CustomAddCardSelect 
            selectOptions={CardTypeUtils.CARD_TYPE_OPTIONS}
            setSelectedOption={(cardType) => CardTypeUtils.convertCardTypeToDialogToOpenType(cardType, (dialogToOpen) => dispatch(setOpenedDialog(dialogToOpen)))}
        />
    </List>
  );
};

export default CardDetailsWindowNoCardBody;

const StyledText = styled.div`
    font-size: 14px;
    color: ${listItemLightGray};
    width: 352px;
    height: 40px;
    font-weight: 400;
    font-family: 'Inter';
    line-height: 24.35px;
`;