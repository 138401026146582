import React from 'react';
import moment from 'moment';
import './App.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import NavigationProvider from './components/navigation/NavigationProvider';
import { ReactNotifications } from 'react-notifications-component';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSignalrConnectionToHub } from 'hooks/useSignalrEncoder';

/* eslint-disable camelcase */
function App() {
  const auth0Config = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    },
    useRefreshToken: true,
    cacheLocation: 'localstorage'
  }

  // Used for getting dates from components like DateRangePicker
  moment.locale('en-gb');
  moment.updateLocale("en", { week: { dow: 1 }});

  const baseAppThemeStyles = {
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          // Removing scrollbar display from the MUI list components (especially for the timepicker, so it remains scrollable but without a scrollbar taking place)
          '&& .MuiList-root': {
            padding: '0',
            margin: '0',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none',},
          },
        },
      },
    },
  };

  const baseAppTheme = createTheme({components: {...baseAppThemeStyles,},});
  useSignalrConnectionToHub();

  return (
    <div className="App">
      <Auth0Provider {...auth0Config}>
        <ReactNotifications />
        <ThemeProvider theme={baseAppTheme}>
            <BrowserRouter>
              <NavigationProvider />
            </BrowserRouter>
        </ThemeProvider>
      </Auth0Provider>
    </div>
  );
}

export default App;
