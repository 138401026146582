import EncoderModel from 'model/EncoderModel';
import { notifyError } from './NotificationService';

export const isEncoderSelectedOrError = (encoder?: EncoderModel) => {
  if (!encoder) {
    notifyError('Please select an Encoder before proceeding to this action!','',);
    return false;
  }
  return true;
};
