import * as React from 'react';
import styled from 'styled-components';
import { black, dialogMenuOptionBorderGray, listItemLightGray, red } from 'constants/theme';
import {ReactComponent as Search} from 'media/search.svg';
import { StyledTextField } from '../selects/WritableSelectField';
import Autocomplete from '@mui/material/Autocomplete';
import { KeyCode } from 'model/enums';
import OptionModel from 'model/OptionModel';
import { customScrollBarCSSProperties } from 'constants/globalStyle';
import InputFieldLabel from '../commons/InputFieldLabel';
import InputFieldError from '../commons/InputFieldError';

type Props = {
  searchBarWidth?: string;
  searchBarHeight?: string;
  inputValue?: OptionModel;
  searchbarLabel?: string;
  isRequired?: boolean;
  invalidFormMessage?: string;
  selectOptions?: OptionModel[];
  forceOptionSelection?: boolean;
  disabled?: boolean;
  setValue: (optionModel: OptionModel) => void;
  setInputChange: (optionModel: OptionModel) => void;
  handleOnEnter: () => void;
};

const SearchBarField = ({
  inputValue,
  searchbarLabel,
  searchBarWidth,
  searchBarHeight,
  isRequired,
  invalidFormMessage,
  selectOptions = [],
  forceOptionSelection,
  disabled,
  setValue,
  setInputChange,
  handleOnEnter
}: Props) => {

  const [open, setOpen] = React.useState(false);
  const closePopper = () => setOpen(false);
  const openPopper = () => setOpen(true);

  const placeholderText = "Search by name, user id...";

  const defaultInputHeight = "4.4vh";

  const handleSearchByKey = (e) => {
    if (e.keyCode === KeyCode.enter) {
      handleOnEnter();
      closePopper();
    }
  };

  const handleInputChange = (newValue?: string | null) => {
    const selectedOptionModel = selectOptions.find(option => option.displayLabelAndId().toLowerCase() === newValue?.toLowerCase());
    if (selectedOptionModel) {
      setValue(selectedOptionModel);
    } else {
      if (newValue) {
        setInputChange(new OptionModel(newValue ));
      } else {
        setInputChange(new OptionModel(""));
      }
    }
  };

  const filterOptions = (stateValue: string) => selectOptions
    .filter(option => !option || option.displayFullOptionModel().toLowerCase().includes(stateValue.toLowerCase()))
    .map(filteredOption => filteredOption.displayLabelAndId());

  return (
      <StyledLabelAndInputHolder>
        <InputFieldLabel 
          inputLabel={searchbarLabel}
          isRequired={isRequired}
        />
        <SearchBarDiv 
          width={searchBarWidth} 
          height={searchBarHeight}
        >
          <StyledAutocomplete
            sx={{
              width: "100%",
              "& .MuiAutocomplete-popupIndicator": { transform: "none" },
              "& .MuiFormControl-root" : { 
                display: "grid",
                height: searchBarHeight ?? defaultInputHeight,
                maxHeight: searchBarHeight
              },
            }}
            renderInput={(params) => (
              <StyledTextField
                {...params}
                placeholder={placeholderText}
                variant="outlined"
                error={!!invalidFormMessage}
              />
            )}
            freeSolo
            forcePopupIcon
            open={open}
            onOpen={openPopper}
            onClose={closePopper}
            height={searchBarHeight ?? defaultInputHeight}
            width={searchBarWidth ?? "100%"}
            options={selectOptions.map(option => option.displayLabelAndId())}
            filterOptions={(_, state) => filterOptions(state.inputValue)}
            inputValue={inputValue?.displayLabelAndId() ?? ""}
            placeholder={placeholderText}
            onInputChange={(event, value, reason) => {
              if (open 
                && event !== null
                && event.type !== 'blur'
                && event.type !== 'keydown'
                && (forceOptionSelection || 
                (reason !== 'reset' || selectOptions.some(option => option.displayLabelAndId().toLowerCase().includes(value.toLowerCase()))))){ 
                  handleInputChange(value);
              }

              if (reason === 'clear') {
                handleInputChange(undefined);
                handleOnEnter();
              }
            }}
            onKeyDown={handleSearchByKey}
            popupIcon={<Search />}
            ListboxProps={{ sx: customScrollBarCSSProperties({width: "10px"}) }}
            disabled={disabled}
          />
        </SearchBarDiv>
        <InputFieldError 
          invalidFormMessage={invalidFormMessage}
        />
      </StyledLabelAndInputHolder>
  );
};

export default SearchBarField;

const StyledLabelAndInputHolder = styled.div`
  display: grid;
  gap: 8px;
`;

const StyledAutocomplete = styled(Autocomplete)<{
  height?: string, 
  width?: string,
  $isEmpty?: boolean, 
  $isError?: boolean,
}>((props) => ({
  height: `${props.height}`,
  width: `${props.width}`,
  borderRadius: `8px`,
  fontSize: `16px`,
  fontWeight: `400`,
  lineHeight: `24.35px`,
  color: props.$isEmpty ? `${listItemLightGray}` : `${black}`,
  '& fieldset': { border: props.$isError ? `1px solid ${red}` : `1px solid ${dialogMenuOptionBorderGray}` },
  '& .MuiAutocomplete-popupIndicator: hover': {
    cursor: 'pointer',
    background: 'none',
  },
}));

export const SearchBarDiv = styled.div<{ width?: string, height?: string}>((props) => ({
  display: props.width ? "content" : "flex",
  alignItems: "center",
  justifyContent: "end",
  height: props.height ?? "5vh",
  width: props.width ?? "32vw",
}));
