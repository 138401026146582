import { EraseCardStatus } from 'model/enums';
import { EraseCardActions } from 'redux/constants/eraseCardActionts';
import EraseCardActionTypes from 'redux/constants/eraseCardActionTypes';

type EraseCardType = {
  eraseCardStatus: EraseCardStatus;
};

const initialState: EraseCardType = { eraseCardStatus: EraseCardStatus.None };

export const eraseCardReducer = (
  state: EraseCardType = initialState,
  action: EraseCardActions,
): EraseCardType => {
  switch (action.type) {
    case EraseCardActionTypes.SET_ERASE_CARD_STATUS:
      return {
          ...state,
          eraseCardStatus: action.payload,
      };
    default:
      return state;
  }
};
