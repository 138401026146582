import React, { useEffect, useState } from 'react';
import ManageSiteCard from 'components/cards/ManageSiteCard';
import EventCard from '../cards/EventCard';
import GeneralTopLoadingBar from 'components/GeneralTopLoadingBar';
import styled from 'styled-components';
import { useParams, useMatch } from 'react-router';
import { Navigate } from 'react-router';
import { connect } from 'react-redux';
import ConfirmCommandCard from 'components/cards/ConfirmCommandCard';
// Test datas
import Building from 'model/Building';
import { FloorWithBuildingName } from 'model/FloorWithBuildingName';
import OnlineDoor from 'model/OnlineDoor';
import { OnlineDoorCommand } from 'model/enums';
import { notifyWarning } from 'helper/NotificationService';
import { fetchAllSites } from 'redux/actions/siteActions';
import ManageCards from 'components/screens/ManageCards';
import ManageUsers from 'components/screens/ManageUsers';
import { PageNavigation } from 'model/enums';

export function SiteDashboard(props: any) {
  const params = useParams();
  const match = useMatch('site/:id');

  const [isShownConfirmCommandCard, setIsShownConfirmCommandCard] =
    useState(false);
  const [commandBuildings, setCommandBuildings] = useState<Building[]>([]);
  const [commandFloors, setCommandFloors] = useState<FloorWithBuildingName[]>(
    [],
  );
  const [commandDoors, setCommandDoors] = useState<OnlineDoor[]>([]);
  const [command, setCommand] = useState<OnlineDoorCommand | null>(null);

  useEffect(() => {
    !props.siteList.data && props.fetchSites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickConfirmCommand = (
    commandType?: OnlineDoorCommand,
    buildings?: Building[],
    floorsWithBuildingId?: FloorWithBuildingName[],
    doors?: OnlineDoor[],
  ) => {
    if (!commandType || isShownConfirmCommandCard) {
      setIsShownConfirmCommandCard(false);
    } else if (
      buildings?.length === 0 &&
      floorsWithBuildingId?.length === 0 &&
      doors?.length === 0
    ) {
      notifyWarning(
        'Nothing is selected',
        'Could not send command because no doors are selected',
      );
    } else {
      buildings && setCommandBuildings(buildings);
      floorsWithBuildingId && setCommandFloors(floorsWithBuildingId);
      doors && setCommandDoors(doors);
      setCommand(commandType);

      setIsShownConfirmCommandCard(true);
    }
  };

  const RenderPage = (props: any): any => {
    switch (props.location) {
      case PageNavigation.Overview:
      return (
        <>
        <EventCard />
      <ConfirmCommandCard
        setIsShown={setIsShownConfirmCommandCard}
        commandType={command}
        buildings={commandBuildings}
        floorsWithBuildingId={commandFloors}
        doors={commandDoors}
        isShown={isShownConfirmCommandCard}
        siteName={props.id}
      />
      <ManageSiteCard
        siteName={props.id}
        confirmCommand={handleClickConfirmCommand}
      />
        </>
      );
      case PageNavigation.ManageUsers :
        return (
          <ManageUsers />
        );
        case PageNavigation.ManageCards:
        return (
          <ManageCards />
        );
    }
  };

  return props.siteList.loading ? (
    <LoadingBarStickToTop>
      <GeneralTopLoadingBar />
    </LoadingBarStickToTop>
  ) : props.siteList.error ? (
    <h2>{props.siteList.error}</h2>
  ) : !props.siteList.data.some((el) => el.SiteName === params.id) ? ( //If there is no such site redirect to 404
    <Navigate to="/404" />
  ) : match?.params?.id ? (
    <RenderPage id={match?.params?.id} location={props.location} />
  ) : (
    <GeneralTopLoadingBar />
  );
}

const mapStateToProps = (state) => {
  return { siteList: state.siteList };
};

const mapDispatchToProps = (dispatch) => {
  return { fetchSites: () => dispatch(fetchAllSites()) };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDashboard);

const LoadingBarStickToTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
