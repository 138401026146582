import * as React from 'react';
import { EraseCardStatus } from 'model/enums';
import { useDispatch } from 'react-redux';
import { setOpenedDialog } from 'redux/actions/userActions';
import { useSignalrEncoderEvents } from 'hooks/useSignalrEncoder';
import { setEraseCardStatus } from 'redux/actions/eraseCardActions';
import EraseCardConfActionDialog from './EraseCardConfActionDialog';

const EraseCardDialog = () => {
    const dispatch = useDispatch();
    useSignalrEncoderEvents();

    const handleDialogClose = () => {
        dispatch(setOpenedDialog(undefined));
        dispatch(setEraseCardStatus(EraseCardStatus.None));
    }  

    return (
        <React.Fragment>
            <EraseCardConfActionDialog handleDialogClose={handleDialogClose} />
        </React.Fragment>
    );
}

export default EraseCardDialog;