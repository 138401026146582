import * as React from 'react';
import { UserDialogInformationPageFormText, UserDialogInformationPageFormPlaceholderText } from 'model/enums';
import SelectField from 'components/controls/selects/SelectField';
import { selectDepartmentList } from 'redux/selectors/departmentSelector';
import TimeGroup from 'model/TimeGroup';
import DepartmentDetail from 'model/DepartmentDetail';
import { useSelector } from 'react-redux';
import { StyledContentHolder, StyledContentRowHolder, StyledUserDialogContent } from '../UserDialogCommonStyle';
import { InformationError } from 'model/UserDialogFormError';
import { InformationForm } from 'model/UserDialogForm';
import MultiTypeInputField from 'components/controls/inputs/MultiTypeInputField';
import { useTimeGroupsInit } from 'hooks/useStorageInits';

type Props = {
  setForm: (form: InformationForm) => void;
  error: InformationError;
  form: InformationForm;
  disabled?: boolean
};

const UserInformationPage = ({
  setForm,
  error,
  form,
  disabled,
}: Props)  => {

  const [listOfGroups, setListOfGroups] = React.useState<string[]>([]);
  const [listOfDepartments, setListOfDepartments] = React.useState<string[]>([]);
  const groupNameList: TimeGroup[] = useTimeGroupsInit();
  const departmentList: DepartmentDetail[] = useSelector(selectDepartmentList);

  React.useEffect(() => {
    setListOfGroups([...groupNameList.map(item => item.GroupName)]);
  }, [groupNameList]);

  React.useEffect(() => {
    setListOfDepartments([...departmentList.map(item => item.Name)]);
  }, [departmentList]);

  return (
    <StyledUserDialogContent
      width={684}
      height={289}
    >
      <StyledContentHolder>
        <StyledContentRowHolder>
          { disabled ? 
            <MultiTypeInputField
              inputFieldHeight={"40px"}
              inputFieldWidth={"177px"}
              displayedName={UserDialogInformationPageFormText.UserId}
              placeholderInput={UserDialogInformationPageFormPlaceholderText.UserId}
              setValue={(userId) => setForm({...form, userId})}
              inputValue={form.userId}
              invalidFormMessage={error.userIdError}
              inputType='number'
              isDisabled
            />
          : null
          }
          <MultiTypeInputField
            inputFieldHeight={"40px"}
            inputFieldWidth={disabled ? "483px" : "684px"}
            displayedName={UserDialogInformationPageFormText.Name}
            placeholderInput={UserDialogInformationPageFormPlaceholderText.Name}
            setValue={(name) => setForm({...form, name})}
            inputValue={form.name}
            isRequired
            invalidFormMessage={error.userNameError}
            isDisabled={disabled}
          />
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <SelectField
            inputFieldHeight={"40px"}
            inputFieldWidth={"684px"}
            displayedName={UserDialogInformationPageFormText.DepartmentName}
            placeholderInput={UserDialogInformationPageFormPlaceholderText.DepartmentName}
            selectOptions={listOfDepartments}
            setValue={(departmentName) => setForm({...form, departmentName})}
            inputValue={form.departmentName}
            isRequired
            invalidFormMessage={error.departmentNameError}
            isDisabled={disabled}
          />
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <SelectField
            inputFieldHeight={"40px"}
            inputFieldWidth={"684px"}
            displayedName={UserDialogInformationPageFormText.GroupName}
            placeholderInput={UserDialogInformationPageFormPlaceholderText.GroupName}
            selectOptions={listOfGroups}
            setValue={(groupName) => setForm({...form, groupName})}
            inputValue={form.groupName}
            isRequired
            invalidFormMessage={error.groupNameError}
          />
        </StyledContentRowHolder>
      </StyledContentHolder>
    </StyledUserDialogContent>
  );
};

export default UserInformationPage;