import { EraseCardStatus } from 'model/enums';
import EraseCardActionTypes from 'redux/constants/eraseCardActionTypes';

export const setEraseCardStatus = (data: EraseCardStatus) => {
  return {
    type: EraseCardActionTypes.SET_ERASE_CARD_STATUS,
    payload: data,
  };
};

