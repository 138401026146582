import { IRootState } from 'redux/store';

export const selectCardDialogStorage = (state: IRootState) => state.cardDialogStorage;

export const selectCardDialogForm = (state: IRootState) => state.cardDialogStorage.form;

export const selectCardDialogFormError = (state: IRootState) => state.cardDialogStorage.formError;

export const selectCardDialogIsEdited = (state: IRootState) => state.cardDialogStorage.isEdited;

export const selectCardDialogIsValidationOpen = (state: IRootState) => state.cardDialogStorage.isValidationOpen;

export const selectCardDialogShouldConfirmationDialogSubmit = (state: IRootState) => state.cardDialogStorage.shouldConfirmationDialogSubmit;

export const selectCardDialogIsOpen = (state: IRootState) => state.cardDialogStorage.isDialogOpen;
