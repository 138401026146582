import { displayInputName} from 'helper/Util';
import * as React from 'react';
import { StyledInputFieldComponents } from '../ControlsCommonStyle';
import styled from 'styled-components';
import Input from '@mui/material/Input';
import { dialogMenuOptionBorderGray, lightGrayBackground, red, white } from 'constants/theme';
import InputAdornment from '@mui/material/InputAdornment';
import { KeyCode } from 'model/enums';
import { InputProps } from 'model/InputProps';

type Props = {
    inputFieldHeight: string;
    inputFieldWidth: string;
    displayedName?: string;
    placeholderInput?: string;
    inputValue?: string | number;
    isDisabled?: boolean;
    isRequired?: boolean;
    invalidFormMessage?: string;
    inputType?: string;
    isMultiline?: boolean;
    numberOfMultilineRows?: number;
    adornmentIcon?: string;
    restrictKeysToPress?: KeyCode[];
    inputProps?: InputProps;
    defaultValue?: string | number;
    isStepperDisplayed?: boolean;
    setValue?: (value?: string) => void
};

const InputFieldTemplate = ({
    inputFieldHeight,
    inputFieldWidth,
    displayedName = "",
    placeholderInput = "",
    inputValue,
    isDisabled = false,
    isRequired = false,
    invalidFormMessage,
    inputType = "text",
    isMultiline = false,
    numberOfMultilineRows = 2,
    adornmentIcon,
    restrictKeysToPress = [],
    inputProps,
    defaultValue,
    isStepperDisplayed,
    setValue = () => null,
}: Props) => {

    const IS_FORM_INVALID = !!invalidFormMessage;

    const getStartAdornment = () => {
        return (adornmentIcon ? <InputAdornment position="start">{adornmentIcon}</InputAdornment> : null);
    }

    const handleIsFieldLengthHitUpperLimit = (value: string) => {
        return (inputProps?.maxLength && inputProps.maxLength < value.length);
    }

    const handleOnChange = (e: any) => {
        const value = e.target.value;
        if (handleIsFieldLengthHitUpperLimit(value)) {
            return e.preventDefault(); 
        }
        setValue((value.trim() ? value : undefined));
    }

    const preventKeyToDisplayIfPressedKeyCodeIsRestricted = (e: any, keyCodes: KeyCode[]) => {
        const insertedKeyCode: number = e.nativeEvent.keyCode;
        return keyCodes.some((keyCode) => keyCode === insertedKeyCode);
    }

    const handleOnKeyDown = (e: any) => {
        if (preventKeyToDisplayIfPressedKeyCodeIsRestricted(e, restrictKeysToPress)) {
            return e.preventDefault(); 
        }
    }

    return (
        <StyledInputFieldComponents.StyledInputOuterContentHolder
            width={inputFieldWidth}
        >
            <StyledInputFieldComponents.StyledInputInnerContentHolder
                $hasLabel
            >
                <StyledInputFieldComponents.StyledTextLabelHolder>
                    {displayInputName(displayedName, isRequired)}
                </StyledInputFieldComponents.StyledTextLabelHolder>
                <StyledInputFieldComponents.StyledInputHolder
                    height={inputFieldHeight}
                    width={inputFieldWidth}
                >
                    <StyledInput
                        inputProps={inputProps}
                        height={inputFieldHeight}
                        width={inputFieldWidth}
                        placeholder={placeholderInput}
                        disableUnderline={true}
                        disabled={isDisabled}
                        value={inputValue ?? defaultValue}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                        onChange={(e) => handleOnChange(e)}
                        required={isRequired}
                        error={IS_FORM_INVALID}
                        multiline={isMultiline}
                        rows={numberOfMultilineRows}
                        startAdornment={getStartAdornment()}
                        type={inputType}
                        $isInvalid={IS_FORM_INVALID}
                        $isDisabled={isDisabled}
                        $isStepperDisplayed={isStepperDisplayed}
                    />
                </StyledInputFieldComponents.StyledInputHolder>
                { IS_FORM_INVALID ? 
                    <StyledInputFieldComponents.StyledErrorTextHolder>
                        {invalidFormMessage}
                    </StyledInputFieldComponents.StyledErrorTextHolder>       
                : null }
            </StyledInputFieldComponents.StyledInputInnerContentHolder>
        </StyledInputFieldComponents.StyledInputOuterContentHolder>
    );
};

export default InputFieldTemplate;

const StyledInput = styled(Input)<{ height?: string, width?: string, $isDisabled?: boolean, $isInvalid?: boolean, $isStepperDisplayed?: boolean }>((props) => ({
    height: `${props.height}`,
    maxHeight: `${props.height}`,
    width: `${props.width}`,
    maxWidth: `${props.width}`,
    border: (props.$isInvalid ? `1px solid ${red}` : `1px solid ${dialogMenuOptionBorderGray}`),
    backgroundColor: (props.$isDisabled ? `${lightGrayBackground}` : `${white}`),
    padding: `7px`,
    borderRadius: `8px`,
    fontSize: `16px`,
    fontWeight: `400`,
    lineHeight: `24.35px`,
    "& ::-webkit-inner-spin-button" : { display: props.$isStepperDisplayed ? "inherit" : "none" }
  }));