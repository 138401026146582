import CardDialogForm from 'model/CardDialogForm';
import CardDialogFormError from 'model/CardDialogFormError';
import { CardDialogActions } from 'redux/constants/cardDialogActions';
import CardDialogTypes from 'redux/constants/cardDialogTypes';

type CardDialogType = {
  form?: CardDialogForm;
  formError?: CardDialogFormError;
  isEdited: boolean;
  isValidationOpen: boolean;
  shouldConfirmationDialogSubmit: boolean;
  isDialogOpen: boolean;
};

const initialState: CardDialogType = {
  form: undefined,
  formError: undefined,
  isEdited: false,
  isValidationOpen: false,
  shouldConfirmationDialogSubmit: false,
  isDialogOpen: false,
};

export const cardDialogReducer = (
  state: CardDialogType = initialState,
  action: CardDialogActions,
): CardDialogType => {
  switch (action.type) {
    case CardDialogTypes.SET_CARD_DIALOG_FORM:
      return {
        ...state,
        form: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_FORM_ERROR:
      return {
        ...state,
        formError: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_IS_EDITED:
      return {
        ...state,
        isEdited: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_IS_VALIDATION_OPEN:
      return {
        ...state,
        isValidationOpen: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_CONFIRMATION_DIALOG_SUBMIT:
      return {
        ...state,
        shouldConfirmationDialogSubmit: action.payload,
      };
    case CardDialogTypes.SET_CARD_DIALOG_IS_OPEN:
      return {
        ...state,
        isDialogOpen: action.payload,
      };
    default:
      return state;
  }
};
