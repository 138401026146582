import * as React from 'react';
import DialogTemplate from '../DialogTemplate';
import { LocalStorageKeys, EncoderStatus, EncoderTestingDialogTexts} from 'model/enums';
import DialogTexts from 'model/DialogTexts';
import { useDispatch, useSelector } from 'react-redux';
import { selectEncoderStorage } from 'redux/selectors/encoderSelector';
import { blue } from 'constants/theme';
import { setAwaitsEncoderResponse, setEncoderConfirmationOpen, setSelectedEncoderStatus } from 'redux/actions/encoderActions';
import EncoderLocalStorageModel from 'model/EncoderLocalStorageModel';

const EncoderConfirmationDialog = () => {
  const {selectedEncoder, isEncoderConfirmationDialogOpen} = useSelector(selectEncoderStorage);
  const dispatch = useDispatch();

  const encoderConfirmationDialog: DialogTexts = {
    title: `Testing out ${selectedEncoder?.Name}`,
    contentText: (<div>Did you hear the beep?</div>),
    submitButton: "Yes",
    cancelButton: "No"
  };

  const handleClickOnConfirmationClose = () => {
    dispatch(setSelectedEncoderStatus(EncoderStatus.EncoderIsInactive));
    handleCloseOfConfirmationDialog();
  }

  const handleClickOnConfirmationSubmit = () => {
    if (selectedEncoder) {
      const encoderLocalStorageModel: EncoderLocalStorageModel = { encoderId: selectedEncoder.Id };
      localStorage.setItem(LocalStorageKeys.Encoder, JSON.stringify(encoderLocalStorageModel));
      dispatch(setSelectedEncoderStatus(EncoderStatus.EncoderIsActivated));
    }
    handleCloseOfConfirmationDialog();
  }

  const handleCloseOfConfirmationDialog = () => {
    dispatch(setEncoderConfirmationOpen(false));
    dispatch(setAwaitsEncoderResponse(false));
  }

  return (
      <DialogTemplate
        handleSubmit={handleClickOnConfirmationSubmit}
        handleClose={handleClickOnConfirmationClose}
        open={isEncoderConfirmationDialogOpen}
        dialogText={encoderConfirmationDialog}
        submitButtonColor={blue}
        dialogId={EncoderTestingDialogTexts.ConfirmationDialogTitle}
      />
  );
};

export default EncoderConfirmationDialog;
