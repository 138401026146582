import * as React from 'react';
import { displayInputName} from 'helper/Util';
import { StyledInputFieldComponents } from '../ControlsCommonStyle';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import styled from 'styled-components';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { dialogMenuOptionBorderGray, red } from 'constants/theme';
import updateLocale from 'dayjs/plugin/updateLocale';
import { customScrollBarCSSProperties } from 'constants/globalStyle';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', { weekStart: 1 });

type Props = {
    inputFieldHeight: string;
    inputFieldWidth: string;
    displayedName?: string;
    inputValue?: dayjs.Dayjs | null;
    isDisabled?: boolean;
    isRequired?: boolean;
    invalidFormMessage?: string;
    hasLabel?: boolean;
    setValue: (value: any) => void;
};

const SingleTimePicker = ({
    inputFieldHeight,
    inputFieldWidth,
    displayedName = "",
    inputValue = null,
    isDisabled = false,
    isRequired = false,
    invalidFormMessage,
    hasLabel = true,
    setValue,
}: Props) => {

    const IS_FORM_INVALID = !!invalidFormMessage;

    const handleOnChange = (e: dayjs.Dayjs) => {
        setValue(e);
    }

    return (
        <StyledInputFieldComponents.StyledInputOuterContentHolder
            width={inputFieldWidth}
        >
            <StyledInputFieldComponents.StyledInputInnerContentHolder
                $hasLabel={hasLabel}
            >
                <StyledInputFieldComponents.StyledTextLabelHolder>
                    {displayInputName(displayedName, isRequired)}
                </StyledInputFieldComponents.StyledTextLabelHolder>
                <StyledInputFieldComponents.StyledInputHolder
                    height={inputFieldHeight}
                    width={inputFieldWidth}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                    >
                        <StyledTimePicker
                            format="HH:mm:ss"
                            views={['hours', 'minutes', 'seconds']}
                            height={inputFieldHeight}
                            width={inputFieldWidth}
                            value={inputValue}
                            onChange={(e) => handleOnChange(e)}
                            $isFormInvalid={IS_FORM_INVALID}
                            disabled={isDisabled}
                            ampm={false}
                            slotProps={{ popper: { sx: { '& .MuiList-root' : customScrollBarCSSProperties() } }}}
                        />
                    </LocalizationProvider>
                </StyledInputFieldComponents.StyledInputHolder>
                { IS_FORM_INVALID ? 
                    <StyledInputFieldComponents.StyledErrorTextHolder>
                        {invalidFormMessage}
                    </StyledInputFieldComponents.StyledErrorTextHolder>       
                : null }
            </StyledInputFieldComponents.StyledInputInnerContentHolder>
        </StyledInputFieldComponents.StyledInputOuterContentHolder>
    );
};

export default SingleTimePicker;

const StyledTimePicker = styled(TimePicker)<{ height?: string, width?: string, $isFormInvalid?: boolean }>((props) => ({ 
    '& div' : {
        height: `${props.height}`,
        maxHeight: `${props.height}`,
        width: `${props.width}`,
        maxWidth: `${props.width}`,
        padding: `0px 16px 0px 0px`,
        borderRadius: `8px`,
        fontSize: `16px`,
        fontWeight: `400`,
        lineHeight: `24.35px`,
        '& div' : { display: 'contents' },
        '& fieldset' : { border: (props.$isFormInvalid ? `1px solid ${red}` : `1px solid ${dialogMenuOptionBorderGray}`) },
    }
}));