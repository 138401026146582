import * as React from 'react';
import styled from 'styled-components';
import ViewOptionWindowCard from './userWindow/ViewOptionWindowCard';
import InformationCard from './userWindow/InformationCard';
import CardDetailCard from './userWindow/CardDetailCard';
import AdditionalInfoCard from './userWindow/AdditionalInfoCard';
import ImageCard from './userWindow/ImageCard';
import NoSelectionCard from './userWindow/NoSelectionCard';
import { customScrollBarCSSProperties } from 'constants/globalStyle';
import {  PageNavigation, WindowCardRenders } from 'model/enums';
import { renderNoSelectionUserContent, renderNoSelectionCardContent } from 'constants/dialogTexts';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { useSelector } from 'react-redux';
import { selectPageNavigation, selectSelectedUserRow, selectUserList } from 'redux/selectors/userSelector';
import UserModel from 'model/UserModel';
import CardModel from 'model/CardModel';
import { getCardById } from 'API/commands/CardCommands';
import DoorListCard from './userWindow/viewDoorListCards/DoorListCard';
import { useDispatch } from 'react-redux';
import { setOpenedDialog } from 'redux/actions/userActions';

const WindowCard = () => {

  const pageNavigation = useSelector(selectPageNavigation);
  const selectedUserTableRecordRow = useSelector(selectSelectedUserRow);
  const actualSite = ActualSiteLocator();
  const listOfUsers: UserModel[] = useSelector(selectUserList);
  const [cardModelToDisplay, setCardModelToDisplay] = React.useState<CardModel | null>(null);
  const WindowCardComponentsByPageNavigation = pageNavigation === PageNavigation.ManageUsers 
    ? [WindowCardRenders.Image, WindowCardRenders.Information, WindowCardRenders.CardDetails, WindowCardRenders.AdditionalInformation] 
    : [WindowCardRenders.CardDetails, WindowCardRenders.Information, WindowCardRenders.DoorList];
  const dispatch = useDispatch();

  const getCardByUserCardId = async() => {
    if (selectedUserTableRecordRow) {
      const userModel: UserModel | undefined = listOfUsers.find((user) => Number(user.UserNumber) === selectedUserTableRecordRow.userId);
      if (userModel && userModel.CardID) {
        const cardModel = await getCardById(actualSite, userModel.CardID);
        if (cardModel) {
          setCardModelToDisplay(cardModel);
          return;
        }
      }
    }
    setCardModelToDisplay(null);
  }

  React.useEffect(() => {
    getCardByUserCardId();
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedUserTableRecordRow]);

  const callWindowElements = (windowCardRenders: WindowCardRenders, callback: (element: JSX.Element) => void) => {
    if (selectedUserTableRecordRow) {
      const windowElementHandler = Object.freeze({
        [WindowCardRenders.Image]: () => callback(<ImageCard/>),
        [WindowCardRenders.Information]: () => callback(<InformationCard cardModelToDisplay={cardModelToDisplay}/>),
        [WindowCardRenders.CardDetails]: () => callback(<CardDetailCard handleFetchOfUserCards={(isOpen) => isOpen ? getCardByUserCardId() : null} cardModelToDisplay={cardModelToDisplay}/>),
        [WindowCardRenders.AdditionalInformation]: () => callback(<AdditionalInfoCard selectedUserTableRecordRow={selectedUserTableRecordRow} />),
        [WindowCardRenders.DoorList]: () => callback(<DoorListCard handleFetchOfUserCards={(isOpen) => isOpen ? getCardByUserCardId() : null} cardModelToDisplay={cardModelToDisplay}/>),
      });
      windowElementHandler[windowCardRenders.toString()].call();
    }
  }

  const renderWindowComponent = (windowCardRenders: WindowCardRenders) => {
    let component = <React.Fragment></React.Fragment>
    callWindowElements(windowCardRenders, (element) => component = element );
    return component;
  }

  return (
      <StyledWindowContainer>
      { selectedUserTableRecordRow === undefined ? (
        <NoSelectionCard 
          cardContent={pageNavigation === PageNavigation.ManageUsers 
            ? renderNoSelectionUserContent()
            : renderNoSelectionCardContent((dialogToOpen) => dispatch(setOpenedDialog(dialogToOpen)))}
        />
      ) :
        <React.Fragment>
          <div>
            <ViewOptionWindowCard />
          </div>
          <StyledWindowBody>
            { WindowCardComponentsByPageNavigation.map((windowCardRender, index) => (
              <div key={index}>
                {renderWindowComponent(windowCardRender)}
              </div>
            ))}
          </StyledWindowBody>
        </React.Fragment>
      }
      </StyledWindowContainer>
  );
};

export default WindowCard;

export const StyledWindowContainer = styled.div`
  width: 99.9%;
  height: 90%;
  align-self: end;
`;

export const StyledWindowBody = styled.div`
  height: 90%;
  overflow-x: hidden;
  margin-right: -10px;
  ${customScrollBarCSSProperties()}
`;