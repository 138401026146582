import * as React from 'react';
import { DialogToOpen, EncoderResponseDescription, EncoderStatus, EraseCardEncoderResponses, EraseCardStatus, EventMessageType, ReadCardStatus } from 'model/enums';
import { useDispatch } from 'react-redux';
import { notifyError, notifySuccess } from 'helper/NotificationService';
import { useSelector } from 'react-redux';
import { selectEncoderStorage } from 'redux/selectors/encoderSelector';
import { setEraseCardStatus } from 'redux/actions/eraseCardActions';
import { setAwaitsEncoderResponse, setEncoderCardResponse, setEncoderConfirmationOpen, setEncoderReadCardStatus, setSelectedEncoderStatus } from 'redux/actions/encoderActions';
import { selectOpenedDialog, selectSelectedUserRow } from 'redux/selectors/userSelector';
import { setOpenedDialog } from 'redux/actions/userActions';
import Connector from 'helper/SignalRConnection';
import { addEventToStore } from 'redux/actions/eventActions';
import { addDoorStatusToStore } from 'redux/actions/doorStatusActions';

export const useSignalrEncoderEvents = () => {
  const openedDialog = useSelector(selectOpenedDialog);
  const selectedUserRow = useSelector(selectSelectedUserRow);
  const {encoderCardResponse, isEncoderConfirmationDialogOpen} = useSelector(selectEncoderStorage);
  const dispatch = useDispatch();

  const handleCardIsNotDetectedError = () => notifyError("Card is not detected! Please place a card on the Encoder.", "");

  React.useEffect(() => {
    if (encoderCardResponse?.Command) {
      switch(encoderCardResponse.Command) {
        case EncoderResponseDescription.EraseCard:
          handleEraseCardSignalrEvents();
          break;
        case EncoderResponseDescription.ReadCard:
          handleReadCardSignalrEvents();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [encoderCardResponse]);

  const handleEraseCardSignalrEvents = () => {
    if (encoderCardResponse?.Description) {
      switch(encoderCardResponse.Description){
        case EraseCardEncoderResponses.CardNotDetected:
          dispatch(setEraseCardStatus(EraseCardStatus.EraseFailed));
          handleCardIsNotDetectedError();
          break;
        case EraseCardEncoderResponses.Success:
          dispatch(setEraseCardStatus(EraseCardStatus.EraseSuccess));
          notifySuccess("Card is successfully erased!", "");
          break;
      }
    }
  }

  const handleReadCardSignalrEvents = () => {
    switch(openedDialog) {
      case DialogToOpen.EraseCardReadActionDialog:
        eraseCardReadActionDialogReadCardEvents();
        break;
      case DialogToOpen.EncoderTesterDialog:
        encoderTesterDialogReadCardEvents();
        break;
      case DialogToOpen.ReadCardDialog:
        readCardDialogEvents();
        break;
    }
  }

  const eraseCardReadActionDialogReadCardEvents = () => {
    if (encoderCardResponse?.Command) {
      switch(encoderCardResponse.Description) {
        case EncoderResponseDescription.CardIsBlank:
          notifyError("The read card is blank! Please read again a card on the encoder!", "");
          break;
        case EncoderResponseDescription.RequestFailed:
          dispatch(setEraseCardStatus(EraseCardStatus.ReadFailed));
          handleCardIsNotDetectedError();
          break;
        default:
          const isReadPhysicalCardTheSameAsTheSelectedUserCard = Number(encoderCardResponse?.UserId) === selectedUserRow?.globalId;
          if (isReadPhysicalCardTheSameAsTheSelectedUserCard) {
            dispatch(setEraseCardStatus(EraseCardStatus.ReadSuccess));
            dispatch(setOpenedDialog(DialogToOpen.EraseCardConfirmationActionDialog));
          } else {
            dispatch(setEraseCardStatus(EraseCardStatus.ReadFailed));
            notifyError("The selected card and the read physical card are not the same! Please read again a card on the encoder!", "");
          }
          break;
      }
    }
  }

  const encoderTesterDialogReadCardEvents = () => {
    dispatch(setAwaitsEncoderResponse(false));
    if (encoderCardResponse?.Description === EncoderResponseDescription.RequestFailed) {
      if (!isEncoderConfirmationDialogOpen) {
          dispatch(setSelectedEncoderStatus(EncoderStatus.EncoderIsInactive));
      }
    } else {
      dispatch(setSelectedEncoderStatus(EncoderStatus.EncoderIsActivated));
      dispatch(setEncoderConfirmationOpen(false));
    }
  }

  const readCardDialogEvents = () => {
    if (encoderCardResponse?.Command) {
      switch(encoderCardResponse.Description) {
        case EncoderResponseDescription.CardIsBlank:
          dispatch(setEncoderReadCardStatus(ReadCardStatus.ReadBlankCard));
          break;
        case EncoderResponseDescription.RequestFailed:
          dispatch(setEncoderReadCardStatus(ReadCardStatus.ReadFailed));
          break;
        default:
          dispatch(setEncoderReadCardStatus(ReadCardStatus.ReadSuccess));
          break;
      }
    }
  }
}

export const useSignalrConnectionToHub = () => {
  const { signalrEvents } = Connector();
  const dispatch = useDispatch();

  React.useEffect(() => {
    signalrEvents((message) => {
      const messageObject = JSON.parse(message);
      switch(messageObject.MessageType) {
        case EventMessageType.DoorEvent:
          dispatch(addEventToStore(messageObject.Message, messageObject.SiteName));
          break;
        case EventMessageType.DoorStatus:
          dispatch(addDoorStatusToStore(messageObject.Message));
          break;
      }
      switch(messageObject.Command) {
        case EncoderResponseDescription.EraseCard:
        case EncoderResponseDescription.ReadCard:
          dispatch(setAwaitsEncoderResponse(false));
          dispatch(setEncoderCardResponse(messageObject));
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}