import { InputFieldValidator } from 'model/enums';
import InputFieldValidatorModel from 'model/InputFieldValidatorModel';
import { NumberLimitsUtil } from './NumberLimitsUtil';
import { CustomInputFieldValidator } from './ValidatorsUtil';

type InputProps = {
  isRequired?: boolean;
  maximumTextLength?: string;
  lowerBound?: number;
  upperBound?: number;
};

export const validate = (
  inputValidators: InputFieldValidatorModel[],
  valueToValidate: any,
) =>
  CustomInputFieldValidator.validateInputFieldByValidators(
    inputValidators,
    valueToValidate,
  );

export const textFieldValidators = (props?: InputProps) => [
  new InputFieldValidatorModel(
    props?.isRequired
      ? InputFieldValidator.ERROR_IF_NULL
      : InputFieldValidator.SKIP_VALIDATION_IF_EMPTY,
  ),
  new InputFieldValidatorModel(
    InputFieldValidator.ERROR_IF_TEXT_LENGTH_IS_TOO_LONG_THEN_X,
    props?.maximumTextLength ?? NumberLimitsUtil.INPUT_TEXT_CHARACTER_MAX_LENGTH,
  )
];

export const numberFieldValidators = (props?: InputProps) => [
    new InputFieldValidatorModel(
      props?.isRequired
        ? InputFieldValidator.ERROR_IF_NULL
        : InputFieldValidator.SKIP_VALIDATION_IF_EMPTY,
    ),
    new InputFieldValidatorModel(
        InputFieldValidator.ERROR_IF_VALUE_IS_LOWER_THEN_X, 
        props?.lowerBound ?? 0,
    ),
    new InputFieldValidatorModel(
        InputFieldValidator.ERROR_IF_VALUE_IS_MORE_OR_EQUAL_THEN_X,
        props?.upperBound ?? NumberLimitsUtil.UBYTE_UPPER_LIMIT_OVERFLOW,
    ),

];

export const selectorFieldValidators = (props?: InputProps) => [
    new InputFieldValidatorModel(
      props?.isRequired
        ? InputFieldValidator.ERROR_IF_NULL
        : InputFieldValidator.SKIP_VALIDATION_IF_EMPTY,
    )
];

export const dateFieldValidators = (props?: InputProps) => [
    new InputFieldValidatorModel(
        props?.isRequired
          ? InputFieldValidator.ERROR_IF_NULL
          : InputFieldValidator.SKIP_VALIDATION_IF_EMPTY,
      ),
      new InputFieldValidatorModel(InputFieldValidator.ERROR_IF_DATE_NOT_VALID_DATE),
];
