import { Dispatch } from 'redux';
import EncoderModel from 'model/EncoderModel';
import { GetEncoders } from 'API/commands/EncoderCommands';
import EncoderActionTypes from 'redux/constants/encoderActionTypes';
import { EncoderStatus, ReadCardStatus } from 'model/enums';
import EncoderCardResponse from 'model/EncoderCardResponse';

export const setEncoderRequest = () => {
  return { type: EncoderActionTypes.ENCODERS_REQUESTED };
};
export const setEncoderSuccess = (data: EncoderModel[]) => {
  return {
    type: EncoderActionTypes.REQUEST_ENCODERS_SUCCESS,
    payload: data,
  };
};
export const setEncoderFailure = (err: any) => {
  return {
    type: EncoderActionTypes.REQUEST_ENCODERS_FAILURE,
    payload: err,
  };
};

export const requestEncoders = (actualSite: string, dispatch: Dispatch) => {
  dispatch(setEncoderRequest());
  GetEncoders(actualSite)
    .then((listOfEncoders) => {
      if (listOfEncoders && listOfEncoders.length > 0) {
        dispatch(setEncoderSuccess(listOfEncoders));
      }
    })
    .catch((err) => {
      dispatch(setEncoderFailure(err));
    });
};

export const setSelectedEncoder = (data?: EncoderModel) => {
  return {
    type: EncoderActionTypes.SET_SELECTED_ENCODER,
    payload: data,
  };
}

export const setSelectedEncoderStatus = (data: EncoderStatus) => {
  return {
    type: EncoderActionTypes.SET_SELECTED_ENCODER_STATUS,
    payload: data,
  };
}

export const setEncoderConfirmationOpen = (data: boolean) => {
  return {
    type: EncoderActionTypes.SET_ENCODER_CONFIRMATION_OPEN,
    payload: data,
  };
}

export const setAwaitsEncoderResponse = (data: boolean) => {
  return {
    type: EncoderActionTypes.SET_AWAITS_ENCODER_RESPONSE,
    payload: data,
  };
}

export const setEncoderCardResponse = (data?: EncoderCardResponse) => {
  return {
    type: EncoderActionTypes.SET_ENCODER_CARD_RESPONSE,
    payload: data,
  };
}

export const setEncoderReadCardStatus = (data: ReadCardStatus) => {
  return {
    type: EncoderActionTypes.SET_ENCODER_READ_CARD_STATUS,
    payload: data,
  };
}