import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import { DialogToOpen, UserDialogPages } from 'model/enums';
import UserImage from './contentLeftSide/UserImage';
import MenuOptionDialog from './contentLeftSide/MenuOptionDialog';
import UserInformationPage from './contentRightSide/UserInformationPage';
import UserAdditionalInformationPage from './contentRightSide/UserAdditionalInformationPage';
import UserAccessPage from './contentRightSide/UserAccessPage';
import UserBluetoothPage from './contentRightSide/UserBluetoothPage';
import UserTableRecord from 'model/UserTableRecord';
import ConfirmationDialog from '../ConfirmationDialog';
import UserDialogForm from 'model/UserDialogForm';
import ValidateUserForm from './ValidateUserForm';
import UserDialogFormError, { AccessError, AdditionalInformationError, BluetoothError, InformationError } from 'model/UserDialogFormError';
import DeleteDialog from '../DeleteDialog';
import { isInfoCircleDisplayed, UserForm, UserFormError } from './UserDialogConsts';
import { connect, useDispatch, useSelector } from 'react-redux';
import { selectIsBleUserLoading, selectUser, selectUserList } from 'redux/selectors/userSelector';
import { customScrollBarCSSProperties, dialogContainer } from 'constants/globalStyle';
import { selectLastUsedDate } from 'redux/selectors/lastUsedDateSelector';
import { PhonesUtil } from 'helper/PhonesUtil';
import { PHONE_COUNTRY_CODE_LIST } from 'constants/arrays';
import { selectMatchedDoorGroups } from 'redux/selectors/doorGroupSelector';
import { clearBuildings, fetchAllBuildings } from 'redux/actions/buildingActions';
import { fetchAllDoorGroups } from 'redux/actions/doorGroupActions';
import { clearRooms, fetchAllRooms } from 'redux/actions/roomActions';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { isNotFilledOutAnything, stopPropagationForTab } from 'helper/DialogUtils';
import DialogActionButtons from '../cardDialog/cardComponents/DialogActionButtons';
import { notifyInfo } from 'helper/NotificationService';
import { DatesUtil } from 'helper/DatesUtil';

type Props = {
    fetchBuildings: any,
    selectedUserTableRecordRow?: UserTableRecord;
    openAddUserDialog: boolean;
    setOpenAddUserDialog: (setOpenAddUserDialog: boolean) => void;
    setRefreshUserList: () => void;
    dialogToOpen: DialogToOpen | undefined;
};

const UserCardDialog = ({
    fetchBuildings,
    selectedUserTableRecordRow,
    openAddUserDialog,
    setOpenAddUserDialog,
    setRefreshUserList,
    dialogToOpen,
}: Props) => {

    const userList = useSelector(selectUserList);
    const accessLastUsedDate = useSelector(selectLastUsedDate);
    const USER_DIALOG_MENU_OPTIONS = Object.values(UserDialogPages);
    const IS_USER_DIALOG_OPEN_AS_EDIT = dialogToOpen === DialogToOpen.EditUserDialog;
    const SELECTED_USER_RECORD = IS_USER_DIALOG_OPEN_AS_EDIT ? selectedUserTableRecordRow : undefined;
    const [isConfirmationDialogOpen, setIsConformationDialogOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [userDialogFormError, setUserDialogFormError] = React.useState<UserDialogFormError>(UserFormError.INIT_USER_DIALOG_FORM_ERROR);
    const [isSubmitClicked, setSubmitClicked] = React.useState<boolean>(false);
    const [userDialogForm, setUserDialogForm] = React.useState<UserDialogForm>(UserForm.getUserDialogForm(undefined, userList, accessLastUsedDate));
    const [selectedPageName, setSelectedPageName] = React.useState<string>(UserDialogPages.Information);
    const [isSaveButtonDisabled, setSaveButtonDisabled] = React.useState<boolean>(true);
    const selectedDoorGroups = useSelector(selectMatchedDoorGroups(userDialogForm?.accessForm?.doorGroups));
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();
    const IS_BLUETOOTH_PAGE_DISABLED = selectedDoorGroups.length === 0 || selectedDoorGroups.every(doorGroup => doorGroup.Rooms.length === 0);
    const [initUserForm, setInitUserForm] = React.useState(UserForm.getUserDialogForm(SELECTED_USER_RECORD, userList, accessLastUsedDate));
    const bleUserState = useSelector(selectUser(SELECTED_USER_RECORD?.globalId))?.UserAccess?.BleUserState;
    const isBleUserLoading = useSelector(selectIsBleUserLoading);

    const initTheUserFormToEmpty = () => {
        const initForm = UserForm.getUserDialogForm(SELECTED_USER_RECORD, userList, accessLastUsedDate);
        setInitUserForm(initForm);
        setUserDialogForm(initForm);
    }

    React.useEffect(() => {
        initTheUserFormToEmpty();
    }, [SELECTED_USER_RECORD, userList, accessLastUsedDate])

    React.useEffect(() => {
        if (openAddUserDialog && selectedPageName === UserDialogPages.Access) {
            const currentDateTime = DatesUtil.getCurrentDateAndTimeInDayJs();
            const combinedDateTime = DatesUtil.combineDateAndTime(userDialogForm.accessForm.startDate, userDialogForm.accessForm.startTime);
            const initForm = UserForm.getUserDialogForm(SELECTED_USER_RECORD, userList, accessLastUsedDate);
            setInitUserForm({
                    ...initForm,
                    accessForm: {
                        ...initForm.accessForm,
                        startDate: userDialogForm.accessForm.isUnlimitedDateChecked ? null : currentDateTime,
                        startTime: userDialogForm.accessForm.isUnlimitedDateChecked ? null : currentDateTime,
                    }
                });
            if (userDialogForm.accessForm.startDate 
                && combinedDateTime && combinedDateTime.isBefore(currentDateTime)) {
                setUserDialogForm({
                    ...userDialogForm,
                    accessForm: {
                        ...userDialogForm.accessForm,
                        startDate: userDialogForm.accessForm.isUnlimitedDateChecked ? null : currentDateTime,
                        startTime: userDialogForm.accessForm.isUnlimitedDateChecked ? null : currentDateTime,
                    }
                });
            }
        }
    }, [openAddUserDialog, selectedPageName])

    React.useEffect(() => {
        if (openAddUserDialog) {

            // Loading the Buildings and storing them in the redux storage.
            dispatch(clearBuildings());
            fetchBuildings(actualSite);

            // Loading the Door Groups and storing them in the redux storage.
            fetchAllDoorGroups(actualSite, dispatch);

            // Loading the Rooms and storing them in the redux storage.
            dispatch(clearRooms());
            fetchAllRooms(actualSite, dispatch);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAddUserDialog]);

    React.useEffect(() => {
        setUserDialogFormError(UserFormError.INIT_USER_DIALOG_FORM_ERROR);
        setSelectedPageName(UserDialogPages.Information);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openAddUserDialog, SELECTED_USER_RECORD, IS_USER_DIALOG_OPEN_AS_EDIT, dialogToOpen]);

    React.useEffect(() => {
        if (openAddUserDialog) {
            setSaveButtonDisabled(isNotFilledOutAnything(userDialogForm, initUserForm));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDialogForm]);

    const handleClickOnClose = () => { isNotFilledOutAnything(userDialogForm, initUserForm) ? handleConfirmDialogSubmit() : setIsConformationDialogOpen(true); };

    const isOptionDisabled = (menuOption: string): boolean => {
        return (menuOption === UserDialogPages.Bluetooth
            && (userDialogForm.accessForm.userRooms.length === 0
                && IS_BLUETOOTH_PAGE_DISABLED
            ));
    }

    const handleOnSubmit = () => {
        setOpenAddUserDialog(false);
        initTheUserFormToEmpty();
    }

    const handleConfirmDialogSubmit = () => {
        if (!isBleUserLoading) {
            handleOnSubmit();
            handleConfirmDialogClose();
        } else {
            handleWaitForBleServiceToResponse();
            handleConfirmDialogClose();
        }
    }

    const handleConfirmDialogClose = () => {
        setIsConformationDialogOpen(false);
    }

    const handleDeleteDialogSubmit = () => {
        if (!isBleUserLoading) {
            handleOnSubmit();
            setRefreshUserList();
            handleDeleteDialogClose();
        } else {
            handleWaitForBleServiceToResponse();
            handleDeleteDialogClose();
        }
    }

    const handleDeleteDialogClose = () => {
        setIsDeleteDialogOpen(false);
    }

    const isThereAnyErrorMessageInForm = (form: InformationError | AdditionalInformationError | AccessError | BluetoothError) => {
        return Object.values(form).some(error => Boolean(error));
    }

    const isThereAnyErrorInTheSelectedPage = (callback: (isThereAnyError: boolean) => void) => {
        const handler = Object.freeze({
            [UserDialogPages.Information]: () => callback(isThereAnyErrorMessageInForm(userDialogFormError.informationError)),
            [UserDialogPages.AdditionalInformation]: () => callback(isThereAnyErrorMessageInForm(userDialogFormError.additionalInformationError)),
            [UserDialogPages.Access]: () => callback(isThereAnyErrorMessageInForm(userDialogFormError.accessError)),
            [UserDialogPages.Bluetooth]: () => callback(isThereAnyErrorMessageInForm(userDialogFormError.bluetoothError)),
        });
        handler[selectedPageName.toString()].call();
    }

    const setSelectedPageToPageWhereErrorIsDisplayed = () => {
        const pagesToCheck = [
            {
                error: selectedPageName !== UserDialogPages.Bluetooth ? userDialogFormError.bluetoothError : {},
                page: UserDialogPages.Bluetooth
            },
            {
                error: selectedPageName !== UserDialogPages.Access ? userDialogFormError.accessError : {},
                page: UserDialogPages.Access
            },
            {
                error: selectedPageName !== UserDialogPages.AdditionalInformation ? userDialogFormError.additionalInformationError : {},
                page: UserDialogPages.AdditionalInformation
            },
            {
                error: selectedPageName !== UserDialogPages.Information ? userDialogFormError.informationError : {},
                page: UserDialogPages.Information
            },
        ];
        pagesToCheck.forEach(pageToCheck => isThereAnyErrorMessageInForm(pageToCheck.error) ? setSelectedPageName(pageToCheck.page) : null);
    }

    React.useEffect(() => {
        if (openAddUserDialog) {
            isThereAnyErrorInTheSelectedPage((isThereAnyError) => (!isThereAnyError ? setSelectedPageToPageWhereErrorIsDisplayed() : null));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDialogFormError]);

    const handleWaitForBleServiceToResponse = () => {
        notifyInfo("Please wait for the response of the Bluetooth Service before proceeding further.", "");
    }

    return (
      <>
      <Dialog
        open={openAddUserDialog}
        onClose={handleClickOnClose}
        disableScrollLock={true}
        onKeyDown={stopPropagationForTab}
        sx={{".MuiPaper-root" : dialogContainer()}}
      >
        <StyledDialogCardHolder>
            <StyledDialogCardTitle>{SELECTED_USER_RECORD ? 'Edit' : 'Add'} User - {selectedPageName}</StyledDialogCardTitle>
            <StyledDialogContentHolder>
                <StyledDialogContentLeftSide>
                    <UserImage
                        selectedUserTableRecordRow={SELECTED_USER_RECORD}
                        setForm={(imageForm) => setUserDialogForm({...userDialogForm, imageForm})}
                    />

                    {USER_DIALOG_MENU_OPTIONS.map((menuOption, index) => (
                        <MenuOptionDialog
                            key={index} 
                            dialogMenuName={menuOption}
                            selectedPageName={selectedPageName}
                            setSelectedPageName={setSelectedPageName}
                            isDisabled={isOptionDisabled(menuOption)}
                            isInfoCircleDisplayed={
                                menuOption === UserDialogPages.Bluetooth &&
                                isInfoCircleDisplayed(bleUserState)
                            }
                        />
                    ))}

                </StyledDialogContentLeftSide>
                <StyledDialogContentRightSide>
                    {selectedPageName === UserDialogPages.Information ? 
                        <UserInformationPage 
                            error={userDialogFormError.informationError}
                            form={userDialogForm.information}
                            setForm={(information) => setUserDialogForm({
                                ...userDialogForm,
                                information,
                                bluetoothForm: {
                                    ...userDialogForm.bluetoothForm,
                                    username: information.name,
                                }
                            })}
                            disabled={!!SELECTED_USER_RECORD}
                        />
                    : null}

                    {selectedPageName === UserDialogPages.AdditionalInformation ?
                        <UserAdditionalInformationPage
                            error={userDialogFormError.additionalInformationError}
                            form={userDialogForm.additionalInformation}
                            initForm={initUserForm.additionalInformation}
                            setForm={(additionalInformation) => setUserDialogForm({
                                ...userDialogForm,
                                additionalInformation,
                                bluetoothForm: {
                                    ...userDialogForm.bluetoothForm,
                                    mobileCountryCode: additionalInformation.telephone
                                        ? PhonesUtil.extractCountryCodeFromPhoneNumber(additionalInformation.telephone)
                                        : PHONE_COUNTRY_CODE_LIST[0],
                                    mobileNumber: PhonesUtil.extractMobileNumberFromPhoneNumber(additionalInformation.telephone)
                                }
                            })}
                        />
                    : null}

                    {selectedPageName === UserDialogPages.Access ? 
                        <UserAccessPage
                            form={userDialogForm.accessForm}
                            setForm={(accessForm) => setUserDialogForm({...userDialogForm, accessForm})}
                            error={userDialogFormError.accessError}
                            isDateSelectorDisplayed
                        />
                    : null}

                    {selectedPageName === UserDialogPages.Bluetooth ?
                        <UserBluetoothPage 
                            isEdited={!!SELECTED_USER_RECORD}
                            error={userDialogFormError.bluetoothError}
                            form={userDialogForm.bluetoothForm}
                            userTableRecord={selectedUserTableRecordRow}
                            initBluetoothForm={initUserForm.bluetoothForm}
                            setForm={(bluetoothForm) => setUserDialogForm({
                                ...userDialogForm,
                                bluetoothForm,
                                information: {
                                    ...userDialogForm.information,
                                    name: bluetoothForm.username
                                },
                                additionalInformation: {
                                    ...userDialogForm.additionalInformation,
                                    telephone: PhonesUtil.convertCountryCodeAndNumbersToPhoneNumber(bluetoothForm.mobileCountryCode, bluetoothForm.mobileNumber)
                                }
                            })}
                        />
                    : null}
                </StyledDialogContentRightSide>
            </StyledDialogContentHolder>
            <DialogActionButtons 
                displayButtonName={"User"}
                isDialogOpenAsEdit={IS_USER_DIALOG_OPEN_AS_EDIT}
                isSaveButtonDisabled={isSaveButtonDisabled || isBleUserLoading}
                handleClickOnClose={handleClickOnClose}
                setSubmitClicked={() => setSubmitClicked(true)}
                setIsDeleteDialogOpen={() => setIsDeleteDialogOpen(true)}
            />
        </StyledDialogCardHolder>
        <ConfirmationDialog open={isConfirmationDialogOpen} handleSubmit={handleConfirmDialogSubmit} handleClose={handleConfirmDialogClose} />
        <DeleteDialog open={isDeleteDialogOpen} userId={SELECTED_USER_RECORD?.globalId} handleSubmit={handleDeleteDialogSubmit} handleClose={handleDeleteDialogClose} />
      </Dialog>
        <ValidateUserForm
            isUserEdited={!!SELECTED_USER_RECORD}
            isSubmitClicked={isSubmitClicked}
            userDialogForm={userDialogForm}
            isBluetoothPageDisabled={IS_BLUETOOTH_PAGE_DISABLED}
            setUserDialogFormError={setUserDialogFormError}
            handleClickOnClose={handleConfirmDialogSubmit}
            setSubmitClicked={setSubmitClicked}
            setRefreshUserList={setRefreshUserList}
        />
      </>
    );
};

const MapStateToProps = (state) => {
    return {
      buildingList: state.buildingList.buildings,
      doorGroupsList: state.doorGroupsList.doorGroups,
      roomsList: state.roomList.rooms,
    };
};

const MapDispatchToProps = (dispatch) => {
    return { fetchBuildings: (actualsite: string) => fetchAllBuildings(actualsite, dispatch) };
};

export default connect(MapStateToProps, MapDispatchToProps)(UserCardDialog);

export const StyledButtonHolder = styled.div`
    display: flex;
    gap: 15px;
`;

export const StyledDialogActions = styled(DialogActions)`
    height: 37px;
    width: 936px;
    max-height: 37px;
    max-width: 936px;
    padding: 0px 0px 16px 0px;
    margin: auto;
`;

export const StyledDialogActionsHolder = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
`;

export const DeleteButtonStyle = { marginRight: 'auto' };

export const StyledDialogCardHolder = styled.div`
    height: 700px;
    width: 1000px;
`;

export const StyledDialogCardTitle = styled(DialogTitle)`
    font-family: "Inter";
    font-weight: 500;
    font-size: 22px;
    line-height: 26.63px;
    text-align: center;
    padding: 24px;
`;

export const StyledDialogContentHolder = styled.div`
    width: 968px;
    max-width: 968px;
    height: 550px;
    padding: 0px 0px 16px 0px;
    margin: 0 32px;
    display: flex;
    gap: 24px;
`;

export const CustomStyledDialogContentHolder = styled(StyledDialogContentHolder)<{ }>((props) => ({height: '550px'}));

export const StyledDialogContentLeftSide = styled(DialogContent)`
    width: 228px;
    height: 550px;
    max-width: 228px;
    max-height: 550px;
    padding: 0px;
`;

export const StyledDialogContentRightSide = styled.div`
    overflow-x: hidden;
    max-height: 550px;
    padding-right: 5px;
    ${customScrollBarCSSProperties()}
`;