import { useEffect } from 'react';
import { isAllFormInputsValid } from 'helper/DialogUtils';
import CardDialogFormError, { CardDetailsFormError } from 'model/CardDialogFormError';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'redux/store';
import { setCardDialogFormError, setCardDialogIsValidationOpen, setCardDialogShouldConfirmationDialogSubmit } from 'redux/actions/cardDialogActions';
import { setUsersIsRefreshUsersList } from 'redux/actions/userActions';
import { CardFormError } from 'components/cards/dialogs/cardDialog/cardDialogLogic/CardDialogConsts';
import { selectOpenedDialog } from 'redux/selectors/userSelector';
import { DefaultIds, DialogToOpen } from 'model/enums';
import { dateFieldValidators, numberFieldValidators, selectorFieldValidators, validate } from 'helper/CommonInputFieldValidators';
import { useIsCardFieldDisplayed } from './useIsInputDisplayed';
import CardDialogForm from 'model/CardDialogForm';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { makeEmergencyCard, makeFloorCard, makeKeyCard, makeMasterCard } from 'API/commands/CardCommands';
import { DatesUtil } from 'helper/DatesUtil';
import { selectSelectedEncoder } from 'redux/selectors/encoderSelector';
import { isEncoderSelectedOrError } from 'helper/EncoderUtils';

export const useCardDialogValidation = () => {

  const dispatch = useDispatch();
  const openedDialog = useSelector(selectOpenedDialog);
  const { form, isEdited, isValidationOpen } = useSelector((state: IRootState) => state.cardDialogStorage);
  const inputDisplayer = useIsCardFieldDisplayed();
  const actualSite = ActualSiteLocator();
  const selectedEncoder = useSelector(selectSelectedEncoder);

  const geCardDetailsFormErrorModel = (): CardDetailsFormError => {
    return form
      ? {
          user: validate(selectorFieldValidators({isRequired: true}), form.cardDetails.user?.userGlobalId),
          startDate: !form.cardDetails.isUnlimitedDateChecked && openedDialog === DialogToOpen.CreateKeyCardDialog 
            ? validate(dateFieldValidators({isRequired: openedDialog === DialogToOpen.CreateKeyCardDialog}), form.cardDetails.startDate) 
            : undefined,
          startTime: !form.cardDetails.isUnlimitedDateChecked && openedDialog === DialogToOpen.CreateKeyCardDialog 
            ?  validate(dateFieldValidators({isRequired: openedDialog === DialogToOpen.CreateKeyCardDialog}), form.cardDetails.startTime) 
            : undefined,
          lastDate: !form.cardDetails.isUnlimitedDateChecked 
            ? validate(dateFieldValidators({isRequired: true}), form.cardDetails.lastDate) 
            : undefined,
          lastTime: !form.cardDetails.isUnlimitedDateChecked 
            ? validate(dateFieldValidators({isRequired: true}), form.cardDetails.lastTime) 
            : undefined,
          department: inputDisplayer.isDepartmentDisplayed 
            ? validate(selectorFieldValidators({isRequired: true}), form.cardDetails.department?.Name)
            : undefined,
          lockReleaseTime: inputDisplayer.isLockReleaseDisplayed 
            ? validate(numberFieldValidators({isRequired: true}), form.cardDetails.lockReleaseTime) 
            : undefined,
          building: inputDisplayer.isBuildingAndFloorSelectorDisplayed 
            ? validate(selectorFieldValidators({isRequired: true}), form.cardDetails.building) 
            : undefined,
          floor: inputDisplayer.isBuildingAndFloorSelectorDisplayed 
            ? validate(selectorFieldValidators({isRequired: true}), form.cardDetails.floor) 
            : undefined,
        }
      : CardFormError.INIT_CARD_DETAILS_FORM_ERROR;
  };

  const handleClickOnSubmit = () => {
    const dialogFormError: CardDialogFormError = { cardDetails: geCardDetailsFormErrorModel() };
    if (isAllFormInputsValid(dialogFormError) && form) {
      /**
       * TODO: In the [NSP-288] task, construct the new card model with the provided form input values.
       * Pass this newly created object to the "handleAPICall" method, that will create or edit the card.
       */
      handleAPICall(form);
    } else {
      handleFailedValidation(dialogFormError);
    }
  };

  const handleAPICall = async (form: CardDialogForm) => {
    const startDate = form?.cardDetails.startDate && form.cardDetails.isStartDateChecked
      ? form?.cardDetails.startDate.format(DatesUtil.CUSTOM_FULL_DATE_TIME_FORMAT)
      : "";

    const expiryDate = form?.cardDetails.lastDate && !form.cardDetails.isUnlimitedDateChecked
      ? form?.cardDetails.lastDate.format(DatesUtil.CUSTOM_FULL_DATE_TIME_FORMAT)
      : "";

    if (!isEdited) {
      if (!isEncoderSelectedOrError(selectedEncoder)) {
        return;
      }

      switch(openedDialog) {
        case DialogToOpen.CreateKeyCardDialog:
          await makeKeyCard(actualSite, DefaultIds.OperatorId, form.cardDetails.user?.userGlobalId ?? -1, selectedEncoder?.Id ?? -1,
            form.cardDoors.map(door => door.Id), startDate, expiryDate);
          break;
        case DialogToOpen.CreateMasterCardDialog:
          await makeMasterCard(actualSite, DefaultIds.OperatorId, form.cardDetails.user?.userGlobalId ?? -1, selectedEncoder?.Id ?? -1,
            form.cardLevels.map(level => level.isChecked ? '1' : ''), startDate, expiryDate);
          break;
        case DialogToOpen.CreateFloorCardDialog:
          await makeFloorCard(actualSite, DefaultIds.OperatorId, form.cardDetails.user?.userGlobalId ?? -1, selectedEncoder?.Id ?? -1,
            form.cardDetails.building?.Id ?? -1, form.cardDetails.floor?.Id ?? -1, startDate, expiryDate);
          break;
        case DialogToOpen.CreateEmergencyCardDialog:
          await makeEmergencyCard(actualSite, DefaultIds.OperatorId, form.cardDetails.user?.userGlobalId ?? -1, selectedEncoder?.Id ?? -1, startDate, expiryDate);
          break;
      }
      handleSuccessfulValidationEnd();
    } else {
      /**
       * TODO: In a later task, implement here the edition of the card.
       * The card can be Master Card, Floor Card, Key Card, and Emergency Card.
       */
      handleSuccessfulValidationEnd();
    }
  };

  const handleSuccessfulValidationEnd = () => {
    dispatch(setCardDialogFormError(CardFormError.INIT_CARD_DIALOG_FORM_ERROR));
    dispatch(setUsersIsRefreshUsersList(true));
    dispatch(setCardDialogShouldConfirmationDialogSubmit(true));
    closeValidation();
  };

  const handleFailedValidation = (dialogFormError: CardDialogFormError) => {
    dispatch(setCardDialogFormError(dialogFormError));
    closeValidation();
  }

  const closeValidation = () => {
    dispatch(setCardDialogIsValidationOpen(false));
  }

  useEffect(() => {
    if (isValidationOpen) {
      handleClickOnSubmit();
    }
  }, [isValidationOpen]);
}
