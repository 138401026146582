import * as signalR from '@microsoft/signalr';

const URL =
  process.env.REACT_APP_HUB_ADDRESS ??
  'https://dev.nspsecurity.com:444/signalR';

class Connector {
  private connection: signalR.HubConnection;
  public signalrEvents: (
    onMessageReceived: (username: string, message: string) => void,
  ) => void;
  static instance: Connector;
  constructor() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(URL)
      .withAutomaticReconnect()
      .build();
    this.connection.start().catch((err) => {
      document.location.href = '/networkerror';
    });
    this.signalrEvents = (onMessageReceived) => {
      this.connection.on('StatusUpdate', (username, message) => {
        onMessageReceived(username, message);
      });
    };
  }
  public static getInstance(): Connector {
    if (!Connector.instance) Connector.instance = new Connector();
    return Connector.instance;
  }
}

export default Connector.getInstance;
