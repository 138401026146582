import React  from 'react';
import { black, white, yellow } from "constants/theme";
import DialogTexts from "model/DialogTexts";
import TemplateDialog from "./TemplateDialog";
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import UserTableRecord from 'model/UserTableRecord';
import { reportLostCard } from 'API/commands/CardCommands';
import { DefaultIds } from 'model/enums';

type Props = {
    selectedUserTableRecordRow: UserTableRecord | undefined;
    handleSubmit: () => void;
    handleClose: () => void;
    open: boolean;
  };

const ReportLostCardDialog = ({handleSubmit, handleClose, open, selectedUserTableRecordRow}: Props) => {
    const actualSite = ActualSiteLocator();

    const handleDialogSubmit = async () => {
        if (selectedUserTableRecordRow?.userId) {
            await reportLostCard(actualSite, DefaultIds.OperatorId, selectedUserTableRecordRow.cardId ?? DefaultIds.CardId);
            handleSubmit();
        }
    }

    return <TemplateDialog dialogText={LostCardDialogText} open={open} handleSubmit={handleDialogSubmit} handleClose={handleClose} style={LostCardDialogStyle} />;
}

export default ReportLostCardDialog;

export const LostCardDialogText = new DialogTexts(
    "Report Lost Card",
    "You’re about to report this card as lost. Once this operation is done this card will not have access to any locks until it’s reissued.",
    "Report Lost",
    "Cancel"
);

export const LostCardDialogStyle = {
  dialog: { color: black, width: '600px', height: '208px', borderRadius: '20px', textAlign: 'center' as const, marginBottom: '38vh'},
  title: { alignSelf: 'center', fontWeight: 'bold' },
  content: { paddingBottom: '0' },
  contentText: { color: 'rgba(0, 0, 0, 1)' },
  actions: { display: 'flex', justifyContent: 'end', padding: '24px 32px' },
  submitButton: {
      backgroundColor: yellow,
      color: white,
      borderRadius: '8px',
      padding: '8px 24px',
      marginLeft: '1vw',
      textTransform: 'none' as const
  },
  cancelButton: {
      color: black,
      border: '1px solid rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      padding: '8px 24px',
      textTransform: 'none' as const,
  },
};