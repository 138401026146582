import * as React from 'react';
import { CardDialogPages, DialogToOpen } from 'model/enums';
import { StyledDialogCardHolder, StyledDialogCardTitle, StyledDialogContentHolder, StyledDialogContentLeftSide, StyledDialogContentRightSide } from '../userCardDialog/UserCardDialog';
import { isNotFilledOutAnything, stopPropagationForTab } from 'helper/DialogUtils';
import ConfirmationDialog from '../ConfirmationDialog';
import MenuOptionDialog from '../userCardDialog/contentLeftSide/MenuOptionDialog';
import CardLevelsDialog from './menuOptions/CardLevelsDialog';
import CardDetailsDialog from './menuOptions/CardDetailsDialog';
import DialogActionButtons from './cardComponents/DialogActionButtons';
import { CardFormError } from './cardDialogLogic/CardDialogConsts';
import { useSelector } from 'react-redux';
import { setCardDialogForm, setCardDialogFormError, setCardDialogIsEdited, setCardDialogIsOpen, setCardDialogIsValidationOpen, setCardDialogShouldConfirmationDialogSubmit } from 'redux/actions/cardDialogActions';
import { selectUserListStorage } from 'redux/selectors/userSelector';
import { useDispatch } from 'react-redux';
import { setOpenedDialog } from 'redux/actions/userActions';
import { selectCardDialogStorage } from 'redux/selectors/cardDialogSelector';
import { dialogContainer } from 'constants/globalStyle';
import Dialog from '@mui/material/Dialog';
import { useConversionOpenedDialogToCardType } from 'hooks/useCardTypes';
import { useCardDialogValidation } from 'hooks/useValidations';
import { useCardFormBuilder } from 'hooks/useFormBuilder';
import { notifyInfo } from 'helper/NotificationService';
import UserAccessPage from '../userCardDialog/contentRightSide/UserAccessPage';

const CardDialog = () => {

    const INIT_FORM = useCardFormBuilder();
    const [isConfirmationDialogOpen, setIsConformationDialogOpen] = React.useState(false);
    const [selectedPageName, setSelectedPageName] = React.useState<CardDialogPages>(CardDialogPages.CardDetails);
    const [isSaveButtonDisabled, setSaveButtonDisabled] = React.useState(true);
    const [selectedMenuPage, setSelectedMenuPage] = React.useState(<></>);
    const { form, shouldConfirmationDialogSubmit, isDialogOpen, isEdited } = useSelector(selectCardDialogStorage);
    const { selectedUserRow, openedDialog } = useSelector(selectUserListStorage);
    const [dialogMenuOptions, setDialogMenuOptions] = React.useState<CardDialogPages[]>([]);
    const dispatch = useDispatch();
    useCardDialogValidation();
    const cardTypeFromDialogToOpen = useConversionOpenedDialogToCardType();

    React.useEffect(() => {
        if (shouldConfirmationDialogSubmit) {
            dispatch(setCardDialogShouldConfirmationDialogSubmit(false));
            handleConfirmDialogSubmit();
        }
    }, [shouldConfirmationDialogSubmit]);

    React.useEffect(() => {
        if (isDialogOpen) {
            dispatch(setCardDialogFormError(CardFormError.INIT_CARD_DIALOG_FORM_ERROR));
            dispatch(setCardDialogForm(INIT_FORM));
            setSelectedPageName(CardDialogPages.CardDetails);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDialogOpen, openedDialog, INIT_FORM]);

    React.useEffect(() => {
        if (isDialogOpen) {
            if (isNotFilledOutAnything(form, INIT_FORM)) {
                setSaveButtonDisabled(true);
                return;
            }
            if (form && openedDialog) {
                const handler = Object.freeze({
                    [DialogToOpen.CreateKeyCardDialog]: () => setSaveButtonDisabled(form?.cardDoors.length === 0),
                    [DialogToOpen.CreateMasterCardDialog]: () => setSaveButtonDisabled(form?.cardLevels.every(level => !level.isChecked)),
                    [DialogToOpen.CreateEmergencyCardDialog]: () => setSaveButtonDisabled(false),
                    [DialogToOpen.CreateFloorCardDialog]: () => setSaveButtonDisabled(false),
                });
                handler[openedDialog.toString()].call();
            } else {
                setSaveButtonDisabled(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    React.useEffect(() => {
        if (isDialogOpen && openedDialog) {
            const handler = Object.freeze({
                [DialogToOpen.CreateKeyCardDialog]: () => setDialogMenuOptions([CardDialogPages.CardDetails, CardDialogPages.Doors]),
                [DialogToOpen.CreateMasterCardDialog]: () => setDialogMenuOptions([CardDialogPages.CardDetails, CardDialogPages.Levels]),
                [DialogToOpen.CreateEmergencyCardDialog]: () => setDialogMenuOptions([]),
                [DialogToOpen.CreateFloorCardDialog]: () => setDialogMenuOptions([]),
            });
            handler[openedDialog.toString()].call();
        }
    }, [isDialogOpen, openedDialog]);

    const handleOnSubmit = () => {
        dispatch(setCardDialogIsOpen(false));
        dispatch(setOpenedDialog(undefined));
        dispatch(setCardDialogIsEdited(false));
    }

    const handleConfirmDialogSubmit = () => {
        handleOnSubmit();
        handleConfirmDialogClose();
    }

    const handleConfirmDialogClose = () => {
        setIsConformationDialogOpen(false);
    }

    const handleDeleteDialogClose = () => {
        notifyInfo("Card deletion will be implemented later!", "");
    }

    const handleClickOnClose = () => { isNotFilledOutAnything(form, INIT_FORM)
        ? handleConfirmDialogSubmit() 
        : setIsConformationDialogOpen(true); 
    };

    React.useEffect(() => {
        if (isDialogOpen) {
            const handler = Object.freeze({
                [CardDialogPages.CardDetails]: () => setSelectedMenuPage( <CardDetailsDialog /> ),
                [CardDialogPages.Doors]: () => setSelectedMenuPage( <UserAccessPage /> ),
                [CardDialogPages.Levels]: () => setSelectedMenuPage( <CardLevelsDialog /> ),
            });
            handler[selectedPageName.toString()].call();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPageName, form, isDialogOpen]);

    const getCardDialogTitle = () => {
        return `${selectedUserRow && isEdited ? 'Edit ' : 'Add '}
            ${cardTypeFromDialogToOpen}
            ${openedDialog === DialogToOpen.CreateKeyCardDialog || openedDialog === DialogToOpen.CreateMasterCardDialog
                ? ` - ${selectedPageName}`
                : ``}`;
    }

    return (
    <>
        <Dialog
            open={isDialogOpen}
            onClose={handleClickOnClose}
            disableScrollLock={true}
            onKeyDown={stopPropagationForTab}
            sx={{".MuiPaper-root" : dialogContainer()}}
        >
            <StyledDialogCardHolder>
                <StyledDialogCardTitle>
                    {getCardDialogTitle()}
                </StyledDialogCardTitle>
                <StyledDialogContentHolder>
                    { dialogMenuOptions.length > 0 ?
                        <StyledDialogContentLeftSide>
                            {dialogMenuOptions.map((menuOption, index) => (
                                <MenuOptionDialog
                                    key={index}
                                    dialogMenuName={menuOption}
                                    selectedPageName={selectedPageName}
                                    setSelectedPageName={selectedPageName => setSelectedPageName(selectedPageName as CardDialogPages)}
                                />
                            ))}
                        </StyledDialogContentLeftSide>
                    : null }
                    <StyledDialogContentRightSide>
                        {selectedMenuPage}
                    </StyledDialogContentRightSide>
                </StyledDialogContentHolder>
                <DialogActionButtons 
                    displayButtonName={"Card"}
                    isDialogOpenAsEdit={isEdited}
                    isSaveButtonDisabled={isSaveButtonDisabled}
                    handleClickOnClose={handleClickOnClose}
                    setSubmitClicked={() => dispatch(setCardDialogIsValidationOpen(true))}
                    setIsDeleteDialogOpen={() => handleDeleteDialogClose()}
                />
            </StyledDialogCardHolder>
            <ConfirmationDialog
                open={isConfirmationDialogOpen}
                handleSubmit={handleConfirmDialogSubmit}
                handleClose={handleConfirmDialogClose}
            />
        </Dialog>
    </>
    );
};

export default CardDialog;