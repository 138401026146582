import CardDialogForm from 'model/CardDialogForm';
import CardDialogFormError from 'model/CardDialogFormError';
import CardDialogTypes from 'redux/constants/cardDialogTypes';

export const setCardDialogForm = (data?: CardDialogForm) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_FORM,
    payload: data,
  };
};
export const setCardDialogFormError = (data?: CardDialogFormError) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_FORM_ERROR,
    payload: data,
  };
};
export const setCardDialogIsEdited = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_IS_EDITED,
    payload: data,
  };
};
export const setCardDialogIsValidationOpen = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_IS_VALIDATION_OPEN,
    payload: data,
  };
};
export const setCardDialogShouldConfirmationDialogSubmit = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_CONFIRMATION_DIALOG_SUBMIT,
    payload: data,
  };
};
export const setCardDialogIsOpen = (data: boolean) => {
  return {
    type: CardDialogTypes.SET_CARD_DIALOG_IS_OPEN,
    payload: data,
  };
};
