import EncoderCardResponse from 'model/EncoderCardResponse';
import EncoderModel from 'model/EncoderModel';
import { EncoderStatus, ReadCardStatus } from 'model/enums';
import { EncoderActions } from 'redux/constants/encoderActions';
import EncoderActionTypes from 'redux/constants/encoderActionTypes';

type EncoderType = {
  loading: boolean;
  isAwaitsEncoderResponse: boolean;
  isEncoderConfirmationDialogOpen: boolean;
  listOfEncoders: EncoderModel[];
  selectedEncoderStatus: EncoderStatus;
  selectedEncoder?: EncoderModel;
  encoderCardResponse?: EncoderCardResponse;
  readCardStatus: ReadCardStatus,
};

const initialState: EncoderType = {
  loading: true,
  isEncoderConfirmationDialogOpen: false,
  isAwaitsEncoderResponse: false,
  listOfEncoders: [],
  selectedEncoderStatus: EncoderStatus.EncoderIsNotSelectedYet,
  selectedEncoder: undefined,
  encoderCardResponse: undefined,
  readCardStatus: ReadCardStatus.None,
};

export const encoderReducer = (
  state: EncoderType = initialState,
  action: EncoderActions,
): EncoderType => {
  switch (action.type) {
    case EncoderActionTypes.ENCODERS_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case EncoderActionTypes.REQUEST_ENCODERS_SUCCESS:
      return {
        ...state,
        loading: false,
        listOfEncoders: action.payload,
      };
    case EncoderActionTypes.REQUEST_ENCODERS_FAILURE:
      return {
        ...state,
        loading: false,
        listOfEncoders: [],
      };
    case EncoderActionTypes.SET_SELECTED_ENCODER:
      return {
        ...state,
        selectedEncoder: action.payload
      };
    case EncoderActionTypes.SET_SELECTED_ENCODER_STATUS:
      return {
        ...state,
        selectedEncoderStatus: action.payload
      };
    case EncoderActionTypes.SET_ENCODER_CONFIRMATION_OPEN:
      return {
        ...state,
        isEncoderConfirmationDialogOpen: action.payload
      }
    case EncoderActionTypes.SET_AWAITS_ENCODER_RESPONSE:
      return {
        ...state,
        isAwaitsEncoderResponse: action.payload
      }
    case EncoderActionTypes.SET_ENCODER_CARD_RESPONSE:
      return {
        ...state,
        encoderCardResponse: action.payload
      }
    case EncoderActionTypes.SET_ENCODER_READ_CARD_STATUS:
      return {
        ...state,
        readCardStatus: action.payload
      }
    default:
      return state;
  }
};
