import * as React from 'react';
import DialogTemplate from '../DialogTemplate';
import { red } from 'constants/theme';
import { useSelector } from 'react-redux';
import { DefaultIds, DialogToOpen, EraseCardDialogTexts, EraseCardStatus } from 'model/enums';
import { useDispatch } from 'react-redux';
import { selectEraseCardStatus } from 'redux/selectors/eraseCardSelector';
import { setEraseCardStatus } from 'redux/actions/eraseCardActions';
import { eraseCardDialogConfirmationAction } from 'constants/dialogTexts';
import { eraseCard } from 'API/commands/CardCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { selectSelectedEncoder } from 'redux/selectors/encoderSelector';
import { selectOpenedDialog, selectSelectedUserRow } from 'redux/selectors/userSelector';
import { notifyError } from 'helper/NotificationService';
import { setUsersIsRefreshUsersList } from 'redux/actions/userActions';

type Props = {
    handleDialogClose: () => void;
}

const EraseCardConfActionDialog = ({handleDialogClose}: Props) => {

    const openedDialog = useSelector(selectOpenedDialog);
    const isOpen = openedDialog === DialogToOpen.EraseCardConfirmationActionDialog;
    const selectedEncoder = useSelector(selectSelectedEncoder);
    const eraseCardStatus = useSelector(selectEraseCardStatus);
    const selectedUserRow = useSelector(selectSelectedUserRow);
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (isOpen && eraseCardStatus === EraseCardStatus.EraseSuccess) {
            dispatch(setUsersIsRefreshUsersList(true));
            handleDialogClose();
        }
    }, [isOpen, eraseCardStatus])

    const handleEraseCardSubmit = () => {
        if (selectedUserRow?.cardId) {
            eraseCard(actualSite, DefaultIds.OperatorId, selectedUserRow.cardId, selectedEncoder).then((cardResponse) => {
                if (cardResponse) {
                    dispatch(setEraseCardStatus(EraseCardStatus.ErasePending));
                }
            });
        } else {
            notifyError("The user does not possess a card to erase!", "");
        }
    }

    return (
        <DialogTemplate
            handleSubmit={handleEraseCardSubmit}
            handleClose={handleDialogClose}
            open={isOpen}
            dialogText={eraseCardDialogConfirmationAction}
            submitButtonColor={red}
            dialogId={EraseCardDialogTexts.EraseCardTitle}
        />
    );
}

export default EraseCardConfActionDialog;