import { IRootState } from "redux/store";

export const selectIsEncoderListLoading = (state: IRootState) => state.encoderStorage.loading;

export const selectListOfEncoders = (state: IRootState) => state.encoderStorage.listOfEncoders;

export const selectSelectedEncoder = (state: IRootState) => state.encoderStorage.selectedEncoder;

export const selectSelectedEncoderStatus = (state: IRootState) => state.encoderStorage.selectedEncoderStatus;

export const selectIsEncoderConfirmationDialogOpen = (state: IRootState) => state.encoderStorage.isEncoderConfirmationDialogOpen;

export const selectIsAwaitsEncoderResponse = (state: IRootState) => state.encoderStorage.isAwaitsEncoderResponse;

export const selectEncoderCardResponse = (state: IRootState) => state.encoderStorage.encoderCardResponse;

export const selectEncoderReadCardStatus = (state: IRootState) => state.encoderStorage.readCardStatus;

export const selectEncoderStorage = (state: IRootState) => state.encoderStorage;
